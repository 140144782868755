import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ReactComponent as HamburgerIcon } from "../../assets/svg/hamburger.svg";
import secureLocalStorage from "react-secure-storage";

const Header = ({ open, toggleHtmlOverflow }) => {
  const [welcomeMessage, setWelcomeMessage] = useState("Morning");
  const user = secureLocalStorage.getItem("admin-info");

  // console.log(user);

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 11) {
      setWelcomeMessage("Morning");
    } else if (hours >= 11 && hours < 15) {
      setWelcomeMessage("After Noon");
    } else if (hours >= 15 && hours < 20) {
      setWelcomeMessage("Evening");
    } else {
      setWelcomeMessage("Night");
    }
  }, []);

  return (
    <Box
      sx={{
        transform: open ? "translate(10%)" : "translate(0%)",
        transition: "0.5s",
      }}
    >
      <Box sx={{ width: open ? "110%" : "90%", margin: "0 auto" }}>
        <Box
          sx={{
            height: "250px",
            pt: "40px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "max-content",
              gap: "20px",
            }}
          >
            <HamburgerIcon
              onClick={toggleHtmlOverflow}
              style={{ cursor: "pointer" }}
            />

            <Box>
              <Typography sx={{ color: "white", fontSize: "20px" }}>
                Good {welcomeMessage}, {user?.user?.firstname}{" "}
                {user?.user?.lastname}
              </Typography>
              <Typography sx={{ color: "white", fontSize: "14px", mt: "5px" }}>
                <span style={{ textTransform: "capitalize" }}>
                  {user?.user?.role}
                </span>
                {", "}
                {moment(new Date()).format("Do MMMM YYYY")}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              borderRadius: "19px",
              height: "40px",
              width: "220px",
              bgcolor: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 8px",
            }}
          >
            <Typography
              sx={{
                color: "var(--text-medium)",
                fontSize: "14px",
                pl: "10px",
                width: "70%",
              }}
            >
              {user?.user?.firstname} {user?.user?.lastname}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "var(--primary-color)",
                // backgroundColor: "var(--bg-color)",
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                fontSize: "14px",
                color: "white",
              }}
            >
              {user?.user?.firstname[0]} {user?.user?.lastname[0]}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
