import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PersonalInfoDetails from "./EmployeeDetailsTabs/PersonalInfoDetails";
import PersonalContactDetails from "./EmployeeDetailsTabs/PersonalContactDetails";
import OfficeInfoDetails from "./EmployeeDetailsTabs/OfficeInfoDetails";
import SalaryInfoDetails from "./EmployeeDetailsTabs/SalaryInfoDetails";
import EquipmentInfoDetails from "./EmployeeDetailsTabs/EquipmentInfoDetails";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const EmployeeDetails = () => {
  const location = useLocation();
  const employeeId = location?.state?.employeeId;
  const user = secureLocalStorage.getItem("admin-info");
  const [value, setValue] = useState("1");
  const [employeeDetails, setemployeeDetails] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const token = user?.accessToken;
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/employees?id=${employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setemployeeDetails(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [employeeId]);

  return (
    <Box>
      <Box ml={1}>
        <Typography variant="title" className="page-title">
          Employee Details
        </Typography>
      </Box>
      <Box mt={4}>
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              indicatorColor="none"
              textColor="white"
              aria-label="home-tab"
              sx={{
                display: "flex",
                borderBottom: "none", // Add this line to remove bottom border
              }}
            >
              <Tab
                sx={{
                  marginX: 1, // Add horizontal margin between tabs
                  opacity: "1",
                  backgroundColor: "var( --gray)",
                  color: "var(--black)",

                  height: "30px",
                  width: "fit-content",
                  minHeight: "10px",
                  paddingX: 1, // Add horizontal padding to each tab
                  fontSize: { xs: "8px", sm: "10px", md: "12px" },
                  textTransform: "Capitalize",
                  "&.Mui-selected": {
                    backgroundColor: "var(--primary-color)",
                    color: "white",

                    opacity: "1",
                  },
                }}
                label="Personal Information"
                value="1"
              />
              <Tab
                label="Personal Contact Information"
                value="2"
                sx={{
                  marginX: 1, // Add horizontal margin between tabs
                  opacity: "1",
                  backgroundColor: "var( --gray)",
                  color: "var(--black)",

                  height: "30px",
                  width: "fit-content",
                  minHeight: "10px",
                  paddingX: 1, // Add horizontal padding to each tab
                  fontSize: { xs: "8px", sm: "10px", md: "12px" },
                  textTransform: "Capitalize",
                  "&.Mui-selected": {
                    backgroundColor: "var(--primary-color)",
                    color: "white",

                    opacity: "1",
                  },
                }}
              />
              <Tab
                label="Official Information"
                value="3"
                sx={{
                  marginX: 1, // Add horizontal margin between tabs
                  opacity: "1",
                  backgroundColor: "var( --gray)",
                  color: "var(--black)",

                  height: "30px",
                  width: "fit-content",
                  minHeight: "10px",
                  paddingX: 1, // Add horizontal padding to each tab
                  fontSize: { xs: "8px", sm: "10px", md: "12px" },
                  textTransform: "Capitalize",
                  "&.Mui-selected": {
                    backgroundColor: "var(--primary-color)",
                    color: "white",

                    opacity: "1",
                  },
                }}
              />
              <Tab
                label="Salary Information"
                value="4"
                sx={{
                  marginX: 1, // Add horizontal margin between tabs
                  opacity: "1",
                  backgroundColor: "var( --gray)",
                  color: "var(--black)",

                  height: "30px",
                  width: "fit-content",
                  minHeight: "10px",
                  paddingX: 1, // Add horizontal padding to each tab
                  fontSize: { xs: "8px", sm: "10px", md: "12px" },
                  textTransform: "Capitalize",
                  "&.Mui-selected": {
                    backgroundColor: "var(--primary-color)",
                    color: "white",

                    opacity: "1",
                  },
                }}
              />
              <Tab
                label="Equipment Information"
                value="5"
                sx={{
                  marginX: 1, // Add horizontal margin between tabs
                  opacity: "1",
                  backgroundColor: "var( --gray)",
                  color: "var(--black)",

                  height: "30px",
                  width: "fit-content",
                  minHeight: "10px",
                  paddingX: 1, // Add horizontal padding to each tab
                  fontSize: { xs: "8px", sm: "10px", md: "12px" },
                  textTransform: "Capitalize",
                  "&.Mui-selected": {
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    opacity: "1",
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <PersonalInfoDetails employeeDetails={employeeDetails} />{" "}
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <PersonalContactDetails employeeDetails={employeeDetails} />
          </TabPanel>
          <TabPanel value="3">
            <OfficeInfoDetails employeeDetails={employeeDetails} />
          </TabPanel>
          <TabPanel value="4">
            <SalaryInfoDetails employeeDetails={employeeDetails} />
          </TabPanel>
          <TabPanel value="5">
            <EquipmentInfoDetails employeeDetails={employeeDetails} />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default EmployeeDetails;
