import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Dialog,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { addButton } from "../../utility/styles";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});
const headers = [
  "Employee Name",
  "Department Name",
  "Branch Name",
  "Status",
  "Role",
  "Email",
  "Action",
  "Active",
];

const DepartmentManagerList = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const [managers, setManagers] = useState([]);
  const [singleMngrData, setSingleMngrData] = useState({});
  const [open, setOpen] = useState(false);
  const token = user?.accessToken;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/admins`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setManagers(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [token]);

  const handleStatusChange = (id, status) => {
    setLoading(true);
    try {
      Swal.fire({
        icon: "warning",
        title: `Are you sure you want to update Department Status`,
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Ok",
      }).then(async function (res) {
        if (res.isConfirmed) {
          await fetch(
            `https://flyfarerp-413411.de.r.appspot.com/api/company/admins/${id}/update-status`,
            {
              method: "PATCH",
              body: JSON.stringify({
                status: status === "active" ? "inactive" : "active",
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              setLoading(false);
              if (data.success) {
                Swal.fire({
                  icon: "success",
                  title: data.message,
                  confirmButtonColor: "var(--primary-color)",
                  confirmButtonText: "Ok",
                }).then(function () {
                  window.location.reload();
                });
              }
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateRole = async () => {
    await fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/admins/${singleMngrData?.id}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          status: singleMngrData?.status,
          role: singleMngrData?.role,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setOpen(false);
        setLoading(false);
        if (data.success) {
          Swal.fire({
            icon: "success",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            window.location.reload();
          });
        }
      });
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="title" className="page-title">
          Department Manager List
        </Typography>

        <Button
          onClick={() => navigate("/dashboard/department/addManager")}
          sx={addButton}
        >
          Add Manager
        </Button>
      </Box>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell
                    sx={{ textAlign: header === "Action" && "center" }}
                    key={i}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {managers.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {data?.firstname} {data?.lastname}
                  </TableCell>
                  <TableCell> {data?.deptName} </TableCell>
                  <TableCell>{data?.branchName}</TableCell>
                  <TableCell> {data?.status} </TableCell>
                  <TableCell> {data?.role} </TableCell>
                  <TableCell> {data?.email} </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          marginLeft: "10px",
                          bgcolor: "var(--primary-color)",
                          ":hover": {
                            bgcolor: "var(--primary-color)",
                          },
                        }}
                        onClick={() => {
                          setSingleMngrData(data);
                          setOpen(true);
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", color: "white" }}>
                          Update
                        </Typography>
                      </Button>
                    </div>{" "}
                  </TableCell>
                  <TableCell>
                    <Switch
                      disabled={loading}
                      checked={data?.status === "active"}
                      onChange={() => handleStatusChange(data.id, data?.status)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth
      >
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontSize: "18px", textTransform: "capitalize" }}>
            Update Role of {singleMngrData?.deptName} {singleMngrData?.role}
          </Typography>
          <Box className="input-container">
            <label
              htmlFor="role"
              className="input-label"
              style={{ width: "30%" }}
            >
              Select Role
            </label>
            <select
              value={singleMngrData?.role}
              onChange={(e) =>
                setSingleMngrData({ ...singleMngrData, role: e.target.value })
              }
              id="role"
              required
              className="input-field"
              style={{
                padding: "0",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                width: "50%",
              }}
            >
              <option value="" disabled selected>
                Select Role Here
              </option>
              <option value={"admin"}>Admin</option>
              <option value={"manager"}>Manager</option>
            </select>
          </Box>

          <Box mt={2} textAlign={"end"}>
            <Button
              variant="contained"
              sx={{
                bgcolor: "var(--primary-color)",
                ":hover": {
                  bgcolor: "var(--primary-color)",
                },
              }}
              onClick={handleUpdateRole}
            >
              <Typography sx={{ fontSize: "12px", color: "white" }}>
                Update Role
              </Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default DepartmentManagerList;
