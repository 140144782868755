import React from 'react'

const Manage = () => {
  return (
    <div>
      Manage
    </div>
  )
}

export default Manage
