import {
  Box,
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import APILoader from "../../../components/APILoader/APILoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const header = [
  "Name",
  "Branch Name",
  "Department Name",
  "Attendance Date",
  "Request Type",
  "Request Time",
  "Remarks",
  "Roster Name",
];

const btnStyle = {
  fontSize: "14px",
  color: "white",
  textTransform: "capitalize",
};

const PendingAttendance = ({ singleReq ,setIsRefetch}) => {
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  const [openImg, setOpenImg] = useState(false);
  const [crrImg, setCrrImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptRejectOvertime = (status, id) => {
    Swal.fire({
      icon: "warning",
      title: `Are you sure you want to ${
        status === "accepted" ? "accept" : "reject"
      } this overtime ?`,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Ok",
    }).then(async function (res) {
      if (res.isConfirmed) {
        try {
          setIsLoading(true);
          const response = await fetch(
            `https://flyfarerp-413411.de.r.appspot.com/api/company/attendance-requests/${id}`,
            {
              method: "PATCH",
              body: JSON.stringify({ status: status }),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          // console.log(data)
          if (data.success) {
            Swal.fire({
              icon: "success",
              title: data?.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
            //   window.location.reload();
            setIsRefetch(true);
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data?.message || data?.error?.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
          setIsRefetch(false);
        }
      }
    });
  };

  return (
    <Box>
      {isLoading ? (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1800 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {header.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}

                <TableCell sx={{ textAlign: "center" }}> Image</TableCell>

                {user?.user?.role !== "manager" && (
                  <TableCell sx={{ textAlign: "center" }}>Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {singleReq?.map((req, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {req?.employee?.firstname} {req?.employee?.lastname}
                    </TableCell>
                    <TableCell>{req?.employee?.branchName}</TableCell>
                    <TableCell>{req?.employee?.deptName}</TableCell>
                    <TableCell>{req?.attendance?.date}</TableCell>
                    <TableCell>
                      {req?.type} ({req?.attendanceType}){" "}
                    </TableCell>
                    <TableCell>
                      {moment(req?.time, "hh:mm:ss").format("hh:mm:ss A")}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        TransitionComponent={Transition}
                        arrow
                        title={
                          <span style={{ fontSize: "14px" }}>
                            {req?.remarks}
                          </span>
                        }
                      >
                        <span style={{ fontSize: "14px", cursor: "pointer" }}>
                          {req?.remarks?.slice(0, 15).concat("...")}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {req?.attendance?.shift?.roasterName} ({" "}
                      {moment(
                        req?.attendance?.shift?.checkIn,
                        "hh:mm:ss"
                      ).format("hh:mm A")}{" "}
                      -{" "}
                      {moment(
                        req?.attendance?.shift?.checkOut,
                        "hh:mm:ss"
                      ).format("hh:mm A")}
                      ){" "}
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      {req?.image ? (
                        <span
                          onClick={() => {
                            setOpenImg(true);
                            setCrrImg(req?.image);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          View
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleAcceptRejectOvertime("accepted", req?.id)
                          }
                          sx={{
                            background: "var(--primary-color)",
                            "&:hover": { background: "var(--primary-color)" },
                            ...btnStyle,
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={() =>
                            handleAcceptRejectOvertime("rejected", req?.id)
                          }
                          sx={{
                            bgcolor: "var(--text-medium)",
                            ":hover": { bgcolor: "var(--text-medium)" },
                            ...btnStyle,
                          }}
                        >
                          Reject
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={openImg}
        onClose={() => {
          setOpenImg(false);
        }}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box p={2} sx={{ minHeight: "450px" }}>
          <img style={{ width: "100%" }} src={crrImg} alt="" />
        </Box>
      </Dialog>
    </Box>
  );
};

export default PendingAttendance;
