import {
  Box,
  Button,
  ClickAwayListener,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const obj = {
  isOpen: false,
  checkIn: "",
  checkOut: "",
  name: "",
  weekends: "",
};

const EditRoaster = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  const selectedRows = location?.state?.selectedRows || [];

  const [updateEmployee, setUpdateEmployee] = useState(selectedRows);
  const [roasterData, setRoasterData] = useState([]);
  const [initialRoasterData, setInitialRoasterData] = useState([]);
  const [allShift, setAllShift] = useState([...new Array(4)].map(() => obj));
  const [selectedShift, setSelectedShift] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/roasters`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        const sortedData = data?.data
          .sort((a, b) => {
            const timeA = moment(a.checkIn, "HH:mm:ss");
            const timeB = moment(b.checkIn, "HH:mm:ss");
            return timeA.diff(timeB);
          })
          .map((d) => ({ ...d, disabled: false }));
        setInitialRoasterData(sortedData);
        setRoasterData(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  //   console.log(selectedShift);

  const handleRemoveName = (indexToRemove) => {
    if (updateEmployee.length > 1) {
      setUpdateEmployee((prevselectedEmployee) =>
        prevselectedEmployee.filter((_, index) => index !== indexToRemove)
      );
    } else {
      alert("At least one employee has been selected for edit roaster");
    }
  };

  const handleClickAway = (currentIndex) => {
    setAllShift(
      allShift.map((shift, i) => {
        if (i === currentIndex) {
          return {
            ...shift,
            isOpen: false,
          };
        } else {
          return shift;
        }
      })
    );
  };

  const handleSelectRoaster = (currentIndex, currentRoaster) => {
    setSelectedShift({
      ...selectedShift,
      ["shift" + (currentIndex + 1)]: currentRoaster?.id,
    });

    setAllShift(
      allShift.map((shft, index) => {
        if (index === currentIndex) {
          return {
            ...shft,
            isOpen: false,
            checkIn: currentRoaster.checkIn,
            checkOut: currentRoaster.checkOut,
            name: currentRoaster.roasterName,
            weekends: currentRoaster.weekends,
          };
        } else {
          return shft;
        }
      })
    );

    setRoasterData(
      initialRoasterData.filter((roaster) => {
        if (
          roaster.checkIn > currentRoaster.checkOut &&
          roaster.weekends === currentRoaster.weekends
        ) {
          return roaster;
        }
      })
    );
  };

  // console.log(allShift);

  const getTotalDutyTime = (roasters) => {
    let totalDutyTime = 0;
    roasters.forEach((roaster) => {
      if (roaster.checkIn && roaster.checkOut) {
        const checkInTime = new Date(`1970-01-01T${roaster.checkIn}`).getTime();
        const checkOutTime = new Date(
          roaster.checkIn > roaster.checkOut
            ? `1970-01-02T${roaster.checkOut}`
            : `1970-01-01T${roaster.checkOut}`
        ).getTime();
        totalDutyTime += checkOutTime - checkInTime;
      }
    });

    const tempTime = moment.duration(totalDutyTime);
    const hour = tempTime.hours();
    const minute = tempTime.minutes();

    return { hour, minute };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hours = getTotalDutyTime(allShift).hour;
    console.log(hours);

    // if (hours < 8) {
    //   alert("Employees total shift must be 8 hours");
    //   return;
    // }

    let employeeIds = [];

    updateEmployee.forEach((obj) => {
      employeeIds.push(obj.id);
    });

    // console.log(employeeIds);

    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/employees/assign-roasters`;

    const body = {
      employeeIds,
      ...selectedShift,
    };

    const requestBody = JSON.stringify(body);

    try {
      setLoading(true);

      const response = await fetch(url, {
        method: "PATCH",
        body: requestBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/employee");
          window.location.reload();
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      }).then(function () {
        navigate("/dashboard/employee");
      });
    } finally {
      setLoading(false); // Ensure setLoading is set to false even in case of error
    }
  };

  return (
    <Box
      sx={{
        input: {
          border: "none",
          backgroundColor: "var( --input-bgcolor)",
          padding: "10px 8px 10px 8px",
          width: "100%",
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: "600px",
          fontSize: "22px",
          color: "#222222",
        }}
        mb={2}
        mt={1}
      >
        Assign Shift to {updateEmployee[0]?.deptName}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        {updateEmployee.map((row, index) => (
          <Tooltip key={index} title={`${row.firstname} ${row.lastname}`} arrow>
            <Typography
              sx={{
                py: 0.5,
                px: 1,
                background: "var(--primary-color)",
                color: "white",
                width: "fit-content",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {row.firstname} {row.lastname}
              <IconButton
                onClick={() => handleRemoveName(index)}
                sx={{ marginLeft: 1, padding: 0 }}
              >
                <CloseIcon sx={{ fontSize: "18px", color: "white" }} />
              </IconButton>
            </Typography>
          </Tooltip>
        ))}
      </Box>

      <Box
        sx={{
          mt: 3,
          mb: 1,
          "& .MuiSelect-select": {
            padding: "7px",
          },
          "& .MuiSelect-outlined": {
            border: "none",
            outline: "none",
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <Typography
          sx={{
            mt: 2,
            fontFamily: "poppins",
            fontWeight: 500,
            fontSize: "18px",
            color: "#222222",
          }}
        >
          Shift Information
        </Typography>

        {allShift.map((shift, i) => (
          <ClickAwayListener key={i} onClickAway={() => handleClickAway(i)}>
            <Box className="input-container">
              <>
                <Box sx={{ width: "15%", height: "100%" }}>
                  <label htmlFor="salary" className="input-label">
                    <span>Shift{i + 1} </span>
                  </label>
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    width: "85%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    pointerEvents:
                      i === 0 || selectedShift["shift" + i] ? "auto" : "none",
                  }}
                >
                  <Box
                    onClick={() => {
                      setAllShift(
                        allShift.map((shft, index) => {
                          if (index === i) {
                            return {
                              ...shft,
                              isOpen: !shft.isOpen,
                            };
                          } else {
                            return {
                              ...shft,
                              isOpen: false,
                            };
                          }
                        })
                      );
                    }}
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {shift.name ? (
                      <Typography textTransform={"capitalize"}>
                        {shift.name}{" "}
                        {moment(shift?.checkIn, "HH:mm:ss").format("hh:mm A")}-
                        {moment(shift?.checkOut, "HH:mm:ss").format("hh:mm A")}
                      </Typography>
                    ) : (
                      <Typography textTransform={"capitalize"}>
                        Choose a Shift
                      </Typography>
                    )}
                  </Box>

                  {shift?.isOpen && (
                    <Box
                      sx={{
                        position: "absolute",
                        bgcolor: "white",
                        height: "230px",
                        overflowY: "scroll",
                        top: "110%",
                        width: "35%",
                        boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.14)",
                        zIndex: "1000",
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: "1px solid #dadce0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          onChange={(e) =>
                            setRoasterData(
                              initialRoasterData.filter((data) =>
                                data?.roasterName
                                  ?.toLowerCase()
                                  ?.includes(e.target.value.toLowerCase())
                              )
                            )
                          }
                          placeholder="Search Roaster Name"
                          style={{
                            width: "96%",
                            padding: "15px 8px",
                          }}
                        />

                        <Button
                          onClick={() => setRoasterData(initialRoasterData)}
                          sx={{ p: 0, textTransform: "capitalize" }}
                        >
                          Reset
                        </Button>
                      </Box>

                      {roasterData.map((data, index) => {
                        // console.log(data);
                        return (
                          <Box
                            onClick={() => handleSelectRoaster(i, data)}
                            key={index}
                            p={1}
                            sx={{
                              ":hover": {
                                bgcolor: "rgba(0,0,0,0.14)",
                              },
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Typography textTransform={"capitalize"}>
                                {data.roasterName}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {moment(data?.checkIn, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                                -
                                {moment(data?.checkOut, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                              </Typography>
                            </Box>
                            <Typography sx={{ fontSize: "14px" }}>
                              {data.weekends}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </>
            </Box>
          </ClickAwayListener>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Typography
          sx={{
            mt: 2,
            fontFamily: "poppins",
            fontWeight: 500,
            fontSize: "18px",
            color: "#222222",
          }}
        >
          Total Duty Time: {getTotalDutyTime(allShift).hour} hours{" "}
          {getTotalDutyTime(allShift).minute} minutes
        </Typography>
        <Button
          disabled={loading}
          sx={{
            background: "var(--primary-color)",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "var(--primary-color)",
            },
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default EditRoaster;
