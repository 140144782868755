import { Box, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";

const TermsAndCon = () => {
  return (
    <Box
      sx={{
        bgcolor: "var(--primary-color)",
        minHeight: "100%",
        li: { color: "var(--text-medium)", py: 0.5 },
      }}
    >
      <Navbar />

      <Box
        sx={{
          display: "flex",
          mt: 2.5,
          maxWidth: "1400px",
          margin: "0 auto",
          pb: 5,
        }}
      >
        <Typography sx={{ fontSize: "47px", color: "white" }}>
          Terms and Conditions
        </Typography>
      </Box>

      <Box sx={{ bgcolor: "white", borderRadius: "150px 150px 0 0", py: 10 }}>
        <Box sx={{ maxWidth: "1400px", margin: "0 auto" }}>
          <Typography>Last updated: 30/05/2024</Typography>
          <Typography my={2}>
            Welcome to HRM Pro Employee Management System (EMS). By accessing or
            using our EMS, you agree to be bound by these Terms and Conditions.
            Please read them carefully.
          </Typography>
          <Typography sx={{ fontSize: "20px", mb: 1 }}>
            1. Acceptance of Terms
          </Typography>
          <Typography pl={2}>
            By accessing and using our EMS, you accept and agree to be bound by
            the terms and conditions of this agreement. In addition, when using
            our EMS, you shall be subject to any posted guidelines or rules
            applicable to such services. Any participation in this service will
            constitute acceptance of this agreement. If you do not agree to
            abide by the above, please do not use this service.
          </Typography>
          
          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            2. Description of Service
          </Typography>
          <Typography pl={2}>
            Our EMS provides a comprehensive platform for managing employee
            records, including personal details, payroll information, attendance
            records, performance evaluations, and more. The service is intended
            for use by authorized company personnel only.
          </Typography>
          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            3. User Responsibilities
          </Typography>
          <Typography pl={2.5}>Users are responsible for:</Typography>
          <ul>
            <li>
              Maintaining the confidentiality of their account credentials.
            </li>
            <li>
              Ensuring the information provided is accurate and up-to-date.
            </li>
            <li>
              Using the EMS in compliance with all applicable laws and
              regulations.
            </li>
            <li>Not using the EMS for any unauthorized or unlawful purpose.</li>
          </ul>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            4. Privacy and Data Protection
          </Typography>
          <Typography pl={2}>
            We are committed to protecting the privacy and confidentiality of
            our users. Please refer to our Privacy Policy for detailed
            information on how we collect, use, and protect your personal data.
          </Typography>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            5. Intellectual Property
          </Typography>
          <Typography pl={2}>
            All content included in the EMS, such as text, graphics, logos,
            images, and software, is the property of [Your Company Name] or its
            content suppliers and is protected by intellectual property laws.
            Unauthorized use of any material contained on the EMS is strictly
            prohibited.
          </Typography>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            6. Prohibited Activities
          </Typography>
          <Typography pl={2.5}>Users are prohibited for:</Typography>
          <ul>
            <li>Attempting to access unauthorized accounts or data.</li>
            <li>
              Using the EMS to transmit or distribute viruses, malware, or other
              harmful software.
            </li>
            <li>
              Engaging in any activity that disrupts or interferes with the EMS
              or its servers.
            </li>
            <li>Using the EMS for any fraudulent or illegal activities.</li>
          </ul>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            7. Termination
          </Typography>
          <Typography pl={2}>
            We reserve the right to terminate or suspend your access to the EMS,
            without prior notice or liability, for any reason, including but not
            limited to your breach of these Terms and Conditions.
          </Typography>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            8. Limitation of Liability
          </Typography>
          <Typography pl={2}>
            In no event shall HRM Pro, nor its directors, employees, partners,
            agents, suppliers, or affiliates, be liable for any indirect,
            incidental, special, consequential, or punitive damages, including
            without limitation, loss of profits, data, use, goodwill, or other
            intangible losses, resulting from (i) your use or inability to use
            the EMS; (ii) any unauthorized access to or use of our servers
            and/or any personal information stored therein; (iii) any
            interruption or cessation of transmission to or from the EMS.
          </Typography>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            9. Governing Law
          </Typography>
          <Typography pl={2}>
            These Terms and Conditions shall be governed and construed in
            accordance with the laws of Bangladesh, without regard to its
            conflict of law provisions.
          </Typography>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            10. Changes to Terms
          </Typography>
          <Typography pl={2}>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms and Conditions at any time. If a revision is material,
            we will provide at least 30 days' notice prior to any new terms
            taking effect. What constitutes a material change will be determined
            at our sole discretion.
          </Typography>

          <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
            11. Contact Us
          </Typography>
          <Typography pl={2}>
            If you have any questions about these Terms and Conditions, please
            contact us at flyfarint@gmail.com
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndCon;
