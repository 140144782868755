import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  Select,
  MenuItem,
  ClickAwayListener,
  Button,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";

const dayObj = {
  days: [],
  shifts: [...new Array(4)].map((_, i) => {
    return {
      isOpen: false,
      isShiftActive: false,
      checkIn: "",
      checkOut: "",
      name: "",
      roasterId: "",
      shiftLocation: [],
    };
  }),
  dayState: [],
};

const AssignShift = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const selectedRows = location?.state?.selectedRows || [];

  const [loading, setLoading] = useState(false);
  const [allDays, setAllDays] = useState(days);
  const [searchText, setSearchText] = useState("");
  const [roasterData, setRoasterData] = useState([]);
  const [initialRoasterData, setInitialRoasterData] = useState([]);
  const [updateEmployee, setUpdateEmployee] = useState(selectedRows);
  const [dayData, setDayData] = useState([dayObj]);

  const handleRemoveName = (indexToRemove) => {
    if (updateEmployee.length > 1) {
      setUpdateEmployee((prevselectedEmployee) =>
        prevselectedEmployee.filter((_, index) => index !== indexToRemove)
      );
    } else {
      alert("At least one employee has been selected for edit roaster");
    }
  };

  // console.log(selectedRows);

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/roasters`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        const sortedData = data?.data
          .sort((a, b) => {
            const timeA = moment(a.checkIn, "HH:mm:ss");
            const timeB = moment(b.checkIn, "HH:mm:ss");
            return timeA.diff(timeB);
          })
          .map((d) => ({ ...d, disabled: false }))
          .filter(
            (d) =>
              d.branchId === selectedRows[0]?.branchId &&
              d.deptId === selectedRows[0]?.deptId
          );
        setInitialRoasterData(sortedData);
        setRoasterData(sortedData);
        setDayData(dayData.map((d) => ({ ...d, allRoster: sortedData })));
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  const handleRemoveDayData = (index) => {
    if (dayData.length > 1) {
      setDayData(dayData.filter((_, i) => i !== index));
    }
  };

  const handleManageDayDataState = (index, prop, value) => {
    setDayData(
      dayData.map((data, i) => {
        if (i === index) {
          return {
            ...data,
            [prop]: value,
          };
        } else {
          return data;
        }
      })
    );
  };

  const handleManageShiftDays = (index, selected) => {
    handleManageDayDataState(index, "days", selected);

    const result = allDays.filter((item) => !selected.includes(item));
    setAllDays(result);
  };

  const handleRemoveShiftDays = (index, selected, value) => {
    const newSelected = selected.filter((s) => s !== value);
    handleManageDayDataState(index, "days", newSelected);

    const allSelectedDays = dayData
      .map((d) => d.days)
      .flat()
      .filter((d) => d !== value);
    const result = days.filter((item) => !allSelectedDays.includes(item));
    setAllDays(result);
  };

  const handleSelectDayStatus = (index, selected) => {
    setDayData(
      dayData.map((data, i) => {
        if (i === index) {
          return {
            ...data,
            dayState: [selected],
            shifts: data.shifts.map((s, i) => {
              if (i === 0) {
                return {
                  ...s,
                  isShiftActive: selected === "active" ? true : false,
                };
              } else {
                return s;
              }
            }),
          };
        } else {
          return data;
        }
      })
    );
  };

  const handleManageShiftState = (index, prop, value, shiftIndex) => {
    setDayData(
      dayData.map((data, i) => {
        if (i === index) {
          return {
            ...data,
            shifts: data?.shifts.map((shift, shiftInd) => {
              if (shiftIndex === shiftInd) {
                return { ...shift, [prop]: value };
              } else {
                return shift;
              }
            }),
          };
        } else {
          return data;
        }
      })
    );
  };

  const handleSelectLocation = (index, selected, shiftIndex) => {
    handleManageShiftState(index, "shiftLocation", [selected], shiftIndex);
  };

  const handleOpenRoster = (index, value, shiftIndex) => {
    handleManageShiftState(index, "isOpen", value, shiftIndex);
  };

  const handleSelectRoaster = (index, roster, shiftIndex) => {
    setDayData(
      dayData.map((data, i) => {
        if (i === index) {
          const updatedShifts = data?.shifts.map((shift, shiftInd) => {
            if (shiftIndex === shiftInd) {
              return {
                ...shift,
                isOpen: !shift.isOpen,
                checkIn: roster?.checkIn,
                checkOut: roster?.checkOut,
                name: roster?.roasterName,
                roasterId: roster?.id,
              };
            } else if (shiftIndex + 1 === shiftInd) {
              return {
                ...shift,
                isShiftActive: true,
              };
            } else {
              return shift;
            }
          });

          const selectedRoasterIds = updatedShifts
            .map((shift) => shift.roasterId)
            .filter((id) => id);
          const uniqueRoster = roasterData.filter(
            (rosterItem) => !selectedRoasterIds.includes(rosterItem.id)
          );

          return {
            ...data,
            shifts: updatedShifts,
            allRoster: uniqueRoster,
          };
        } else {
          return data;
        }
      })
    );
  };

  const handleSubmit = async () => {
    // console.log(dayData);

    const body = {
      employeeIds: updateEmployee.map((r) => r.id),
      daysData: dayData.map((day) => {
        return {
          days: day.days,
          dayState: day.dayState[0],
          shifts: day.shifts
            .filter((s) => {
              if (s.roasterId) {
                return s;
              }
            })
            .map((s) => ({
              roasterId: s?.roasterId,
              shiftLocation:
                s.shiftLocation.length > 0 ? s.shiftLocation[0] : "",
            })),
        };
      }),
    };

    // console.log(body);

    // return;

    try {
      // const url = `http://192.168.1.103:5000/api/company/shifts`;
      const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/shifts`;
      setLoading(true);

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate(-1);
          // window.location.reload();
        });
      } else {
        // console.log(data)
        Swal.fire({
          icon: "error",
          title: data?.error?.message || "Something went wrong",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try again",
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      });
    } finally {
      setLoading(false); // Ensure setLoading is set to false even in case of error
    }
  };

  // console.log(selectedRows)

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: "600px",
          fontSize: "22px",
          color: "#222222",
        }}
        mb={2}
        mt={1}
      >
        Assign Shift to {updateEmployee[0]?.deptName}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          flexWrap: "wrap",
          maxWidth: "100%",
          mb: 3,
        }}
      >
        {updateEmployee.map((row, index) => (
          <Tooltip key={index} title={`${row.firstname} ${row.lastname}`} arrow>
            <Typography
              sx={{
                py: 0.5,
                px: 1,
                background: "var(--primary-color)",
                color: "white",
                width: "fit-content",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {row.firstname} {row.lastname}
              <IconButton
                onClick={() => handleRemoveName(index)}
                sx={{ marginLeft: 1, padding: 0 }}
              >
                <CloseIcon sx={{ fontSize: "18px", color: "white" }} />
              </IconButton>
            </Typography>
          </Tooltip>
        ))}
      </Box>

      {dayData?.map((data, index) => {
        // console.log(data)
        return (
          <Box key={index} sx={{ mb: 4 }}>
            <Box sx={{ display: "flex" }}>
              <Box
                className="input-container"
                style={{ width: "50%", cursor: "auto" }}
              >
                <label
                  className="input-label"
                  style={{ width: "20%", cursor: "auto" }}
                >
                  Select Days
                </label>

                <Select
                  multiple
                  displayEmpty
                  value={data?.days}
                  onChange={(e) => handleManageShiftDays(index, e.target.value)}
                  sx={{ ...selectStyle, minWidth: "75%", width: "auto" }}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return (
                        <span style={{ color: "var(--text-medium)" }}>
                          Select Days Here
                        </span>
                      );
                    }

                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              <span style={chip.text}>
                                {value}
                                <ClearIcon
                                  style={chip.btn}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleRemoveShiftDays(
                                      index,
                                      selected,
                                      value
                                    );
                                  }}
                                />
                              </span>
                            }
                          />
                        ))}
                      </Box>
                    );
                  }}
                >
                  {allDays.map((day, i) => (
                    <MenuItem sx={{ fontSize: "14px" }} key={i} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              {/* Select Day status */}
              <Box className="input-container" width={"50%"}>
                <label
                  className="input-label"
                  style={{ width: "27%", cursor: "auto" }}
                >
                  Select Day Status
                </label>

                <Select
                  multiple
                  displayEmpty
                  value={data?.dayState}
                  onChange={(e) =>
                    handleSelectDayStatus(
                      index,
                      e.target.value[e.target.value.length - 1]
                    )
                  }
                  sx={{ ...selectStyle, minWidth: "70%", width: "auto" }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <span style={{ color: "var(--text-medium)" }}>
                          Select Day Status Here
                        </span>
                      );
                    } else {
                      return (
                        <span
                          style={{
                            color: "var(--text-medium)",
                            textTransform: "capitalize",
                          }}
                        >
                          {selected[0]}
                        </span>
                      );
                    }
                  }}
                >
                  <MenuItem value={"active"} sx={{ fontSize: "14px" }}>
                    Active
                  </MenuItem>
                  <MenuItem value={"weekend"} sx={{ fontSize: "14px" }}>
                    Weekend
                  </MenuItem>
                </Select>
              </Box>
            </Box>

            {/* Shifts and Shift Location */}

            <Box>
              {data.shifts.map((shift, i) => {
                return (
                  <Box key={i} sx={{ display: "flex" }}>
                    <Box
                      className="input-container"
                      style={{ width: "50%", cursor: "auto" }}
                    >
                      <label
                        htmlFor="salary"
                        className="input-label"
                        style={{ width: "20%", cursor: "auto" }}
                      >
                        Shift {i + 1}
                      </label>
                      <ClickAwayListener
                        onClickAway={() => {
                          handleOpenRoster(index, false, i);
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            width: "75%",
                            // cursor:
                            //   data.dayState[0] === "active"
                            //     ? shift?.isShiftActive
                            //       ? "pointer"
                            //       : "not-allowed"
                            //     : "not-allowed",
                            cursor: shift?.isShiftActive && "pointer",
                          }}
                        >
                          <Box
                            onClick={() => {
                              if (data.dayState[0] === "active") {
                                if (shift?.isShiftActive) {
                                  handleOpenRoster(index, !shift?.isOpen, i);
                                }
                              }
                            }}
                            sx={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {shift.name ? (
                              <Typography
                                sx={{ fontSize: "14px", pl: "14px" }}
                                textTransform={"capitalize"}
                              >
                                {shift.name}{" "}
                                {moment(shift?.checkIn, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                                -
                                {moment(shift?.checkOut, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  pl: "14px",
                                  color: shift?.isShiftActive
                                    ? "var(--text-medium)"
                                    : "#00000061",
                                }}
                              >
                                Select Shift {i + 1} Roster
                              </Typography>
                            )}
                          </Box>

                          {shift?.isOpen && (
                            <Box
                              sx={{
                                position: "absolute",
                                bgcolor: "white",
                                height: "230px",
                                overflowY: "scroll",
                                top: "110%",
                                left: "2%",
                                width: "60%",
                                boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.14)",
                                zIndex: "1000",
                              }}
                            >
                              <Box
                                sx={{
                                  borderBottom: "1px solid #dadce0",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  onChange={(e) => {
                                    setRoasterData(
                                      initialRoasterData.filter((data) =>
                                        data?.roasterName
                                          ?.toLowerCase()
                                          ?.includes(
                                            e.target.value.toLowerCase()
                                          )
                                      )
                                    );
                                    setSearchText(e.target.value);
                                  }}
                                  value={searchText}
                                  placeholder="Search Roaster Name"
                                  style={{
                                    width: "96%",
                                    padding: "15px 8px",
                                  }}
                                />

                                <Button
                                  onClick={() => {
                                    setRoasterData(initialRoasterData);
                                    setSearchText("");
                                  }}
                                  sx={{ p: 0, textTransform: "capitalize" }}
                                >
                                  Reset
                                </Button>
                              </Box>

                              {data?.allRoster.map((data, rosterIndex) => {
                                // console.log(data);
                                return (
                                  <Box
                                    onClick={() =>
                                      handleSelectRoaster(index, data, i)
                                    }
                                    key={rosterIndex}
                                    p={1}
                                    sx={{
                                      ":hover": {
                                        bgcolor: "rgba(0,0,0,0.14)",
                                      },
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      <Typography textTransform={"capitalize"}>
                                        {data.roasterName}
                                      </Typography>
                                      <Typography sx={{ fontSize: "12px" }}>
                                        {moment(
                                          data?.checkIn,
                                          "HH:mm:ss"
                                        ).format("hh:mm A")}
                                        -
                                        {moment(
                                          data?.checkOut,
                                          "HH:mm:ss"
                                        ).format("hh:mm A")}
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          )}
                        </Box>
                      </ClickAwayListener>
                    </Box>

                    <Box className="input-container" width={"50%"}>
                      <label
                        className="input-label"
                        style={{ width: "27%", cursor: "auto" }}
                      >
                        Select Shift Location
                      </label>

                      <Select
                        multiple
                        displayEmpty
                        value={shift?.shiftLocation}
                        onChange={(e) =>
                          handleSelectLocation(
                            index,
                            e.target.value[e.target.value.length - 1],
                            i
                          )
                        }
                        sx={{
                          ...selectStyle,
                          minWidth: "70%",
                          width: "auto",
                          // cursor:
                          //   data.dayState[0] === "active"
                          //     ? shift?.isShiftActive
                          //       ? "pointer"
                          //       : "not-allowed"
                          //     : "not-allowed",
                          // pointerEvents: "none",
                        }}
                        disabled={
                          !(
                            data.dayState[0] === "active" &&
                            shift?.isShiftActive
                          )
                        }
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return (
                              <span style={{ color: "var(--text-medium)" }}>
                                Select Shift Location Here
                              </span>
                            );
                          } else {
                            return (
                              <span
                                style={{
                                  color: "var(--text-medium)",
                                  textTransform: "capitalize",
                                }}
                              >
                                {selected[0]}
                              </span>
                            );
                          }
                        }}
                      >
                        <MenuItem value={"on-site"} sx={{ fontSize: "14px" }}>
                          On Site
                        </MenuItem>
                        <MenuItem value={"on-field"} sx={{ fontSize: "14px" }}>
                          On Field
                        </MenuItem>
                        <MenuItem value={"remote"} sx={{ fontSize: "14px" }}>
                          Remote
                        </MenuItem>
                      </Select>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            {dayData.length > 1 && (
              <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                <Button
                  onClick={() => handleRemoveDayData(index)}
                  sx={{
                    bgcolor: "var(--primary-color)",
                    color: "#ffffff",
                    "&:hover": { bgcolor: "var(--primary-color)" },
                    textTransform: "capitalize",
                  }}
                >
                  Remove
                </Button>
              </Box>
            )}
          </Box>
        );
      })}

      <Box sx={{ display: "flex", gap: "20px", justifyContent: "end", mt: 4 }}>
        {allDays.length > 0 &&
          dayData.map((d) => d.days).flat().length < 8 &&
          dayData.length < 8 && (
            <Button
              onClick={() =>
                setDayData([...dayData, { ...dayObj, allRoster: roasterData }])
              }
              sx={{
                bgcolor: "var(--primary-color)",
                color: "#ffffff",
                "&:hover": { bgcolor: "var(--primary-color)" },
                textTransform: "capitalize",
              }}
            >
              Add Another
            </Button>
          )}
        <Button
          disabled={loading}
          onClick={handleSubmit}
          sx={{
            bgcolor: "var(--primary-color)",
            color: "#ffffff",
            "&:hover": { bgcolor: "var(--primary-color)" },
            textTransform: "capitalize",
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

const days = [
  "Saturday",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];

const selectStyle = {
  fontSize: "14px",
  "& .MuiSelect-select": {
    py: "8px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
    borderColor: "transparent",
  },

  "& .MuiMenu-paper": {
    boxShadow: "0px 10px 20px 0px rgba(0,0,0,0.20)",
  },
};

const chip = {
  text: { display: "flex", alignItems: "center", gap: 1 },
  btn: { fontSize: "14px", cursor: "pointer" },
};

export default AssignShift;
