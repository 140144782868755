import { Box, CircularProgress } from "@mui/material";
import React from "react";

const APILoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        style={{
          color: "var(--primary-color)",
          height: "100px",
          width: "100px",
        }}
      />
    </Box>
  );
};

export default APILoader;
