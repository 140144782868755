import { Box, Typography } from "@mui/material";
import React from "react";

const PersonalContactDetails = ({ employeeDetails }) => {
  const data = employeeDetails[0];
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Personal Contact Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Phone Number</span>
        </label>
        <Typography>{data.personalPhone}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Email</span>
        </label>
        <Typography>{data.personalEmail}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Present Address</span>
        </label>
        <Typography>{data.presentAddress}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Parmanent Address</span>
        </label>
        <Typography>{data.permanentAddress}</Typography>
      </Box>

      <Typography sx={{ mt: 4, fontWeight: 600, mb: 1 }}>
        Emergency Contact Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Phone Number</span>
        </label>
        <Typography>{data.emergencyContact}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Relation</span>
        </label>
        <Typography>{data.emergencyContactRelation}</Typography>
      </Box>
    </Box>
  );
};

export default PersonalContactDetails;
