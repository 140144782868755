import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { submenuButton, addButton } from "../../utility/styles";

const headers = [
  "Employee Id",
  "Employee Name",
  "Office",
  "Department",
  "Status",
  "Disbursement Date",
  "Loan Amount",
  "Number Of Month",
  "Per Month Deduct Amount Wille Be",
  "Mail",
  "Action",
];

const ProvidentFund = () => {
  return (
    <Box>
      <Box
        sx={{ display: "flex", gap: "25px", justifyContent: "space-between" }}
      >
        <Typography variant="title" className="page-title">
         Provident Fund
        </Typography>
      </Box>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ProvidentFund;
