import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
  Switch,
} from "@mui/material";
import { addButton } from "../../utility/styles";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import APILoader from "../../components/APILoader/APILoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const headers = [
  "Department Name",
  "Status",
  "Employee Count",
  "Total Salary",
  "Department Email",
  "Phone Number",
  "Action",
  "Active",
];

const Department = () => {
  const [department, setDepartment] = useState([]);
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [deptId, setDeptId] = useState({});

  const handleOpen = (data) => {
    setOpen(true);
    setFormInfo(data);
    setDeptId(data?.id);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const token = user?.accessToken;

    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/departments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setDepartment(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/departments/${deptId}`;
    const body = JSON.stringify({
      deptName: formInfo.deptName,
      deptEmail: formInfo.deptEmail,
      deptPhone: formInfo.deptPhone,
    });

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/department");
          window.location.reload();
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      }).then(function () {
        navigate("/dashboard/department");
        return false;
      });
    }

    handleClose();
  };

  return (
    <Box>
      {department.length > 0 ? (
        <>
          {/*------------- Page Title -------------*/}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="title" className="page-title">
              Department List
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "15px",
              }}
            >
              <Button
                onClick={() =>
                  navigate("/dashboard/department/departmentManagerList")
                }
                sx={{
                  ...addButton,
                  width: "170px",
                }}
              >
                Department Manager List
              </Button>
              <Button
                onClick={() => navigate("/dashboard/department/addDepartment")}
                sx={addButton}
              >
                Add Department
              </Button>
            </Box>
          </Box>

          {/*------------- Page Content -------------*/}
          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {department.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{data?.deptName}</TableCell>
                      <TableCell> {data?.status} </TableCell>
                      <TableCell>{data?.totalEmployees}</TableCell>
                      <TableCell> {data?.totalSalaray} </TableCell>
                      <TableCell> {data?.deptEmail} </TableCell>
                      <TableCell> {data?.deptPhone} </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Button
                            variant="contained"
                            sx={{
                              marginLeft: "10px",
                              bgcolor: "var(--primary-color)",
                              ":hover": {
                                bgcolor: "var(--primary-color)",
                              },
                            }}
                            onClick={() => {
                              handleOpen(data);
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "white" }}
                            >
                              Update
                            </Typography>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              marginLeft: "10px",
                              bgcolor: "var(--text-medium)",
                              ":hover": {
                                bgcolor: "var(--text-medium)",
                              },
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "white" }}
                            >
                              Delete
                            </Typography>
                          </Button>
                        </div>{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}

      <Dialog
        // open={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="title" className="page-title">
            Update Department Details
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Name</span>
              </label>
              <input
                value={formInfo?.deptName}
                onChange={handleChange}
                name="deptName"
                className="input-field"
                type="text"
                placeholder="Enter Department Name"
                required
              />
            </Box>
            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Email</span>
              </label>
              <input
                value={formInfo?.deptEmail}
                onChange={handleChange}
                name="deptEmail"
                className="input-field"
                type="text"
                placeholder="Enter Email"
                required
              />
            </Box>
            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Phone Number</span>
              </label>
              <input
                name="deptPhone"
                value={formInfo?.deptPhone}
                onChange={handleChange}
                className="input-field"
                type="text"
                placeholder="Enter Phone Number"
                required
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                sx={{
                  color: "#fff",
                  background: "var(--primary-color)",
                  mt: 2,
                  "&:hover": {
                    background: "var(--primary-color)",
                  },
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Department;
