import { Box } from "@mui/material";
import React from "react";

const SalaryAttendance = ({ attendance, salaryData, handleGetSalaryValue }) => {
  // console.log(salary)
  return (
    <Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Total Working Days</span>
        </label>
        <input
          readOnly
          value={String(attendance?.totalActiveDays)}
          onChange={handleGetSalaryValue}
          name="workingDays"
          className="input-field"
          type="number"
          placeholder="Enter Working Days"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Absent Days</span>
        </label>
        <input
          readOnly
          value={String(attendance?.totalAbsentDays)}
          onChange={handleGetSalaryValue}
          name="absentDays"
          className="input-field"
          type="number"
          placeholder="Enter Absent Days"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Present Days</span>
        </label>
        <input
          readOnly
          value={String(attendance?.totalPresentDays)}
          onChange={handleGetSalaryValue}
          name="presentDays"
          className="input-field"
          type="number"
          placeholder="Enter Present Days"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Late Days</span>
        </label>
        <input
          readOnly
          value={String(attendance?.totalLateCheckInDays)}
          onChange={handleGetSalaryValue}
          name="lateDays"
          className="input-field"
          type="number"
          placeholder="Enter Late Days"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Leave Taken</span>
        </label>
        <input
          readOnly
          value={String(attendance?.totalLeaveDays)}
          onChange={handleGetSalaryValue}
          name="lateDays"
          className="input-field"
          type="number"
          placeholder="Enter Late Days"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Holidays</span>
        </label>
        <input
          readOnly
          value={String(attendance?.totalHolidayDays)}
          onChange={handleGetSalaryValue}
          name="lateDays"
          className="input-field"
          type="number"
          placeholder="Enter Late Days"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Weekends</span>
        </label>
        <input
          readOnly
          value={String(attendance?.totalWeekendDays)}
          onChange={handleGetSalaryValue}
          name="lateDays"
          className="input-field"
          type="number"
          placeholder="Enter Late Days"
        />
      </Box>
    </Box>
  );
};

export default SalaryAttendance;
