import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import secureLocalStorage from "react-secure-storage";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import APILoader from "../../components/APILoader/APILoader";

const headers = [
  "Leave Id",
  "Employee Name",
  "Office",
  "Department",
  "Leave Type",
  "Leave From",
  "Leave To",
  "Leave Days",
  "Day Duration",
  "Days",
  "Total Sick Leaves",
  "Taken Sick Leaves",
  "Total Casual Leaves",
  "Taken Casual Leaves",
  "Attachment",
];

const Leave = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [leaveData, setLeaveData] = useState([]);

  useEffect(() => {
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/leave-requests`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse response body as JSON
      })
      .then((data) => {
        // console.log(data);
        setLeaveData(data?.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        // Handle error gracefully
      });
  }, []);

  // console.log(leaveData);
  return (
    <Box>
      {leaveData.length > 0 ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "25px",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="title" className="page-title">
              Leave Request
            </Typography>
          </Box>

          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 2050 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveData.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span
                          onClick={() => {
                            navigate("/dashboard/employeeLeaveRequest", {
                              state: {
                                id: data?.id,
                              },
                            });
                          }}
                          style={{
                            backgroundColor: "#dadce0",
                            padding: "3px 5px",
                            cursor: "pointer",
                          }}
                        >
                          {data?.customId}
                        </span>
                      </TableCell>
                      <TableCell>
                        {data?.firstname} {data?.lastname}
                      </TableCell>
                      <TableCell>{data?.branchName || "N/A"}</TableCell>

                      <TableCell>{data?.deptName || "N/A"}</TableCell>
                      <TableCell>{data?.leaveType || "N/A"}</TableCell>

                      <TableCell>
                        {moment(data?.leaveFrom).format("D MMM, YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(data?.leaveTo).format("D MMM, YYYY")}
                      </TableCell>

                      <TableCell>{data?.leaveDays || "N/A"}</TableCell>

                      <TableCell>{data?.dayDuration || "N/A"}</TableCell>

                      <TableCell>{data?.duration || "N/A"}</TableCell>

                      <TableCell>{data?.totalSickLeaves}</TableCell>

                      <TableCell>{data?.takenSickLeaves}</TableCell>

                      <TableCell>{data?.totalCasualLeaves}</TableCell>

                      <TableCell>{data?.takenCasualLeaves}</TableCell>

                      <TableCell>View</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default Leave;
