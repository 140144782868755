import { Box, Button, CircularProgress } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import Basics from "./components/Basics";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import SalaryAttendance from "./components/SalaryAttendance";
import Others from "./components/Others";
import AdditionalSalary from "./components/AdditionalSalary";
import Swal from "sweetalert2";

const tabStyle = {
  marginX: 1, // Add horizontal margin between tabs
  opacity: "1",
  backgroundColor: "var( --gray)",
  color: "var(--black)",
  height: "30px",
  width: "fit-content",
  minHeight: "10px",
  paddingX: 1, // Add horizontal padding to each tab
  fontSize: { xs: "8px", sm: "10px", md: "12px" },
  textTransform: "Capitalize",
  "&.Mui-selected": {
    backgroundColor: "var(--primary-color)",
    color: "white",
    opacity: "1",
  },
};

const SalaryBuild = () => {
  const location = useLocation();
  const currentDate = location?.state?.currentDate;
  const employee = location?.state?.locationData;
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [value, setValue] = useState("1");
  const [salaryData, setSalaryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [attendance, setAttendance] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(employee)

  useEffect(() => {
    setIsLoading(true);
    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/make-draft`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        employeeId: employee?.employeeId,
        month: String(currentDate.getMonth() + 1),
        year: String(currentDate.getFullYear()),
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === true) {
          // setSalaryData(data?.data);
          setSalaryData(data?.data[0]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching departments:", error);
      });

    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/attendances/monthly-report?employeeId=${
        employee?.employeeId
      }&month=${String(currentDate.getMonth() + 1)}&year=${String(
        currentDate.getFullYear()
      )}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === true) {
          setIsLoading(false);
          setAttendance(data?.data[0]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching departments:", error);
      });
  }, []);

  //   console.log(salaryData);

  const handleGetSalaryValue = (e) => {
    setSalaryData({ ...salaryData, [e.target.name]: Number(e.target.value) });
  };

  const handleGetReason = (e) => {
    setSalaryData({ ...salaryData, [e.target.name]: e.target.value });
  };

  // console.log(value);

  const handleSalaryBuild = async () => {
    Swal.fire({
      icon: "info",
      title: `Are you sure you want to confirm ${attendance?.name} Salary`,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Ok",
    }).then(function (res) {
      if (res.isConfirmed) {
        try {
          // `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/build/${employee?.employeeId}`,

          fetch(
            `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/build/${salaryData?.id}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                meals: salaryData?.meals,
                mealPrice: salaryData?.mealPrice,
                extraAddition: salaryData?.extraAddition,
                extraAdditionReason: salaryData?.extraAdditionReason
                  ? salaryData?.extraAdditionReason
                  : "",
                extraDeduction: salaryData?.extraDeduction,
                extraDeductionReason: salaryData?.extraDeductionReason
                  ? salaryData?.extraDeductionReason
                  : "",
              }),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              console.log(data);

              if (data?.success === true) {
                Swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: data?.message,
                }).then(() => {});
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: data?.message,
                }).then(() => {});
              }
            });
        } catch (error) {
          console.error("Error submitting form data:", error);
          // Show error message using SweetAlert
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  return (
    <Box
      sx={{
        "input:read-only": { color: "var(--text-color)" },
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            height: "80vh",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "inherit",
          }}
        >
          <CircularProgress
            style={{
              color: "var(--primary-color)",
              height: "100px",
              width: "100px",
            }}
          />
        </Box>
      ) : (
        <>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                indicatorColor="none"
                textColor="white"
                aria-label="home-tab"
                sx={{ display: "flex", borderBottom: "none" }}
              >
                <Tab label="Basic" value="1" sx={tabStyle} />
                <Tab label="Attendance" value="2" sx={tabStyle} />
                <Tab label="Deduction" value="3" sx={tabStyle} />
                <Tab label="Addition" value="4" sx={tabStyle} />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Basics
                salaryData={salaryData}
                handleGetSalaryValue={handleGetSalaryValue}
              />
            </TabPanel>
            <TabPanel value="2">
              <SalaryAttendance
                attendance={attendance}
                salaryData={salaryData}
                handleGetSalaryValue={handleGetSalaryValue}
              />
            </TabPanel>
            <TabPanel value="3">
              <Others
                salaryData={salaryData}
                handleGetSalaryValue={handleGetSalaryValue}
                handleGetReason={handleGetReason}
              />
            </TabPanel>
            <TabPanel value="4">
              <AdditionalSalary
                salaryData={salaryData}
                handleGetSalaryValue={handleGetSalaryValue}
                handleGetReason={handleGetReason}
              />
            </TabPanel>
          </TabContext>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              gap: "25px",
            }}
          >
            <Button
              onClick={() => {
                if (value > 1) {
                  setValue(String(Number(value) - 1));
                } else {
                  setValue(value);
                }
              }}
              sx={{
                bgcolor: "var(--primary-color)",
                ":hover": { bgcolor: "var(--primary-color)" },
                color: "white",
                width: "20%",
                textTransform: "capitalize",
              }}
            >
              Previous
            </Button>

            {value === "4" ? (
              <Button
                onClick={handleSalaryBuild}
                sx={{
                  bgcolor: "var(--primary-color)",
                  ":hover": { bgcolor: "var(--primary-color)" },
                  color: "white",
                  width: "20%",
                  textTransform: "capitalize",
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (value < 4) {
                    setValue(String(Number(value) + 1));
                  } else {
                    setValue(value);
                  }
                }}
                sx={{
                  bgcolor: "var(--primary-color)",
                  ":hover": { bgcolor: "var(--primary-color)" },
                  color: "white",
                  width: "20%",
                  textTransform: "capitalize",
                }}
              >
                Next
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SalaryBuild;
