import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import APILoader from "../../components/APILoader/APILoader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddManager = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [deptData, setDeptData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({
    id: "",
    email: "",
  });
  const [selectedDept, setSelectedDept] = useState({
    id: "",
    deptName: "",
  });

  const [selectRole, setSelectRole] = useState("admin");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDeptChange = (event) => {
    setSelectedDept({
      id: event.target.value,
      deptName: deptData.find((dept) => dept.id === event.target.value)
        .deptName,
    });
    // console.log(event);
  };

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/departments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDeptData(data?.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [token]);

  useEffect(() => {
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/employees?deptId=${selectedDept.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setEmployeeData(data?.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [token, selectedDept.id]);

  // console.log(deptData)

  //   const filteredAttendanceData = selectedDept.id
  //     ? attendanceData.filter((data) => data.deptId === selectedDept.id)
  //     : attendanceData;

  // console.log(selectedEmployee);

  const handleAddManager = async () => {
    const body = {
      // email: selectedEmployee.email,
      // password: password,
      employeeId: selectedEmployee.id,
      role: selectRole,
    };

    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/admins`;

    try {
      setIsLoading(true);

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log(data);
      // setOpen(false);
      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate(-1);
        });
      } else {
        console.log(data);
        Swal.fire({
          icon: "error",
          title: data?.error?.message || "Something went wrong",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try again",
        }).then(function () {
          // setOpen(true);
          // navigate("/dashboard/employee");
        });
        throw new Error(data?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      {deptData.length > 0 ? (
        <>
          <Typography variant="title" className="page-title" ml={0.8}>
            Add Manager
          </Typography>

          <Box sx={{ p: 1, mt: 2 }}>
            <Box className="input-container">
              <label className="input-label">
                <span>Select Department</span>
              </label>
              <select
                // value={formData?.deptId}
                // onChange={handleFormChange}

                required
                className="input-field"
                style={{
                  padding: "0",

                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onChange={handleDeptChange}
              >
                <option value="" disabled selected>
                  Select Department Here
                </option>
                {deptData.map((data, index) => (
                  <option key={index} value={data.id}>
                    {data.deptName}
                  </option>
                ))}
              </select>
            </Box>
            <Box className="input-container">
              <label className="input-label">
                <span>Select Employee</span>
              </label>
              <select
                value={selectedEmployee.id}
                onChange={(e) =>
                  setSelectedEmployee({
                    id: e.target.value,
                    email: employeeData?.find(
                      (employee) => employee.id === e.target.value
                    ).email,
                  })
                }
                required
                className="input-field"
                style={{
                  padding: "0",

                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                // onChange={handleDeptChange}
              >
                <option value="" disabled selected>
                  Select Employee Here
                </option>
                {employeeData.map((data, index) => (
                  <option key={index} value={data.id}>
                    {data.firstname} {data.lastname}
                  </option>
                ))}
              </select>
            </Box>
            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Select Role</span>
              </label>
              <select
                value={selectRole}
                // onChange={handleFormChange}
                onChange={(e) => setSelectRole(e.target.value)}
                required
                className="input-field"
                style={{
                  padding: "0",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
              </select>
            </Box>
            {/* <Box className="input-container">
              <label htmlFor="email" className="input-label">
                <span>email</span>
              </label>
              <input
                value={selectedEmployee.email}
                onChange={(e) =>
                  setSelectedEmployee({
                    ...selectedEmployee,
                    email: e.target.value,
                  })
                }
                name="email"
                id="email"
                className="input-field"
                type="email"
                placeholder="Enter Email Name"
                required
              />
            </Box>

            <Box className="input-container">
              <label htmlFor="password" className="input-label">
                <span>Password</span>
              </label>
              <input
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-field"
                type="password"
                placeholder="Enter Password"
                required
              />
            </Box> */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                disabled={isLoading}
                sx={{
                  color: "#fff",
                  background: "var(--primary-color)",
                  mt: 2,
                  "&:hover": {
                    background: "var(--primary-color)",
                  },
                  width: "80px",
                }}
                onClick={handleAddManager}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      height: "25px",
                      width: "25px",
                    }}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default AddManager;
