import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

const OfficePDF = ({ reportData }) => {
  // console.log("report", reportData);
  const table = {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };

  const tableColName = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableCollast = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableCol = {
    width: "8.7%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  };
  const tableColfinal = {
    width: "10.5%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  };

  const tableCell = {
    margin: "3px",
    fontSize: "7px",
    minWidth: "30px", // set minimum width
    minHeight: "14px", // set minimum height
    flexWrap: "wrap", // allow content to wrap
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  };

  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "7px",
  };

  const report = reportData;
  // let firstReportData = report[0]?.date;

  // console.log(reportData);

  const showShift = (shifts, prop) =>
    shifts?.map((data, index, array) => (
      <View
        key={index}
        style={{
          flexDirection: "row",
          borderBottom:
            index !== array.length - 1 ? "1px solid black" : undefined,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Text style={tableCell}>{data[prop] || "N/A"}</Text>
      </View>
    ));
  return (
    <Document>
      <Page>
        <View
          style={{
            padding: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: "10px" }}>Fly Far International</Text>
          <Text style={{ fontSize: "8px" }}>
            Ka/9/A, Hazi Abdul Latif Mansion, Bashundhara Residential Road, Near
            of Jamuna Future Park, Dhaka
          </Text>
          <Text
            style={{
              borderBottomWidth: 0.5,
              borderBottomColor: "black",
              padding: "4px 10px",
              width: "100%",
            }}
          ></Text>
        </View>

        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: "10px",
              fontWeight: 500,
              padding: "5px 5px",
            }}
          >
            {report && report.length > 0 ? (
              <>Office's Employees Attendence Report on {report[0]?.date}</>
            ) : (
              "N/A"
            )}
          </Text>
        </View>

        {/*****   Attendence details table     *****/}

        <View style={{ padding: "10px" }}>
          <View style={table}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ ...tableCol, width: "9.5%" }}>
                <Text style={tableCell}>Office</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}> Total Employee</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Present</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Work From Home</Text>
              </View>
              <View style={tableColfinal}>
                <Text style={tableCell}>Absent </Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Leave</Text>
              </View>
              <View style={tableColfinal}>
                <Text style={tableCell}>Half day Leave</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}> Check In on Time</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}> Check In Late</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}> Check out On Time</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}> Check out Early</Text>
              </View>
            </View>

            {reportData.map((data, index) => (
              <View key={index} style={{ flexDirection: "row" }}>
                <View style={{ ...tableCol, width: "9.5%" }}>
                  <Text style={tableCell}>{data?.branchName}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.totalEmployees}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.totalAttendances}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>0</Text>
                </View>
                <View style={tableColfinal}>
                  <Text style={tableCell}>{data?.noCheckIns}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.inTimeStatus || "N/A"}</Text>
                </View>
                <View style={tableColfinal}>
                  <Text style={tableCell}>{data?.onTimeCheckIns || "N/A"}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.lateCheckIns || "N/A"}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>
                    {data?.onTimeCheckOuts || "N/A"}
                  </Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.earlyCheckOuts || "N/A"}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}> N/A</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OfficePDF;
