import { Box } from "@mui/material";
import React from "react";

const AdditionalSalary = ({
  salaryData,
  handleGetSalaryValue,
  handleGetReason,
}) => {
  // console.log(salary)
  return (
    <Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Conveyance</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.conveyance)}
          onChange={handleGetSalaryValue}
          name="conveyance"
          className="input-field"
          type="number"
          placeholder="Enter Extra Addition"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Overtime</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.overtime)}
          onChange={handleGetSalaryValue}
          name="overtime"
          className="input-field"
          type="text"
          placeholder="Enter Extra Addition"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Bonus</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.bonus)}
          onChange={handleGetSalaryValue}
          name="bonus"
          className="input-field"
          type="number"
          placeholder="Enter Extra Deduction"
        />
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box className="input-container" sx={{ width: "50%" }}>
          <label
            htmlFor="salary"
            className="input-label"
            style={{ width: "35%" }}
          >
            <span>Others Addition</span>
          </label>
          <input
            value={String(salaryData?.extraAddition)}
            onChange={handleGetSalaryValue}
            name="extraAddition"
            className="input-field"
            type="number"
            placeholder="Enter Loss Of Office Equipment"
          />
        </Box>
        <Box className="input-container" sx={{ width: "50%" }}>
          <label
            htmlFor="salary"
            className="input-label"
            style={{ width: "35%" }}
          >
            <span>Others Addition Reason</span>
          </label>
          <input
            value={salaryData?.extraAdditionReason}
            onChange={handleGetReason}
            name="extraAdditionReason"
            className="input-field"
            type="text"
            placeholder="Enter Others Addition Reason"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdditionalSalary;
