import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

const DeptAttendencePDF = ({ reportData, selectedDept }) => {
  // console.log("report", reportData);
  const table = {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };

  const tableColName = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableCollast = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableCol = {
    width: "8.7%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  };
  const tableColfinal = {
    width: "10.5%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  };

  const tableCell = {
    margin: "3px",
    fontSize: "6.5px",
    minWidth: "30px", // set minimum width
    minHeight: "8px", // set minimum height
    flexWrap: "wrap", // allow content to wrap
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
  };

  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "7px",
  };

  const report = reportData;
  // let firstReportData = report[0]?.date;

  // console.log(reportData);

  const showShift = (shifts, prop) =>
    shifts?.map((data, index, array) => (
      <View
        key={index}
        style={{
          flexDirection: "row",
          borderBottom:
            index !== array.length - 1 ? "1px solid black" : undefined,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Text style={tableCell}>{data[prop] || "N/A"}</Text>
      </View>
    ));
  return (
    <Document>
      <Page>
        <View
          style={{
            padding: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: "10px" }}>Fly Far International</Text>
          <Text style={{ fontSize: "8px" }}>
            Ka/9/A, Hazi Abdul Latif Mansion, Bashundhara Residential Road, Near
            of Jamuna Future Park, Dhaka
          </Text>
          <Text
            style={{
              borderBottomWidth: 0.5,
              borderBottomColor: "black",
              padding: "4px 10px",
              width: "100%",
            }}
          ></Text>
        </View>

        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: "10px",
              fontWeight: 500,
              padding: "5px 5px",
            }}
          >
            {report && report.length > 0 ? (
              <>
                {selectedDept.deptName ? selectedDept.deptName : "All"}{" "}
                Employees Attendence Report on{" "}
                {moment(report[0]?.attendanceDate).format("DD MMMM YYYY")}
              </>
            ) : (
              "N/A"
            )}
          </Text>
        </View>

        <View style={{ padding: "10px" }}>
          <View style={table}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ ...tableCol, width: "20%" }}>
                <Text style={tableCell}>Employee Name </Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Status</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Designation</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Ratio</Text>
              </View>
              <View style={tableColfinal}>
                <Text style={tableCell}>Check In </Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Check In Status</Text>
              </View>
              <View style={tableColfinal}>
                <Text style={tableCell}>Check In Remarks</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}> Check Out</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Check Out Status</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Check Out Remarks</Text>
              </View>
            </View>

            {reportData.map((data, index) => (
              <View key={index} style={{ flexDirection: "row" }}>
                <View style={{ ...tableCol, width: "20%" }}>
                  <Text style={tableCell}>{data?.employeeName}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.employeeStatus}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={{ ...tableCell, minWidth: "20px" }}>
                    {data?.designation}
                  </Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>
                    {data?.completeAttendances}/{data?.shiftCount}
                  </Text>
                </View>
                <View style={tableColfinal}>
                  <Text style={tableCell}>
                    {data?.inTime
                      ? moment(data.inTime, "HH:mm:ss").format("h:mm A")
                      : "N/A"}
                  </Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.inTimeStatus || "N/A"}</Text>
                </View>
                <View style={tableColfinal}>
                  <Text style={tableCell}>
                    {" "}
                    {data?.inTimeRemarks?.slice(0, 20)}
                  </Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>
                    {" "}
                    {data?.outTime
                      ? moment(data.outTime, "HH:mm:ss").format("h:mm A")
                      : "N/A"}
                  </Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.outTimeStatus || "N/A"}</Text>
                </View>
                <View style={tableCol}>
                  <Text style={tableCell}>{data?.outTimeRemarks || "N/A"}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DeptAttendencePDF;
