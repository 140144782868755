import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
const styles = { flexCenter: { display: "flex", alignItems: "center" } };

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        ".MuiTypography-root": {
          fontSize: "14px",
          color: "white",
          cursor: "pointer",
        },
        height: "140px",
        ...styles.flexCenter,
        justifyContent: "space-between",
        maxWidth: "1400px",
        margin: "0 auto",
      }}
    >
      <Box>
        <LogoIcon
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          fill="#fff"
        />
      </Box>
      <Box sx={{ ...styles.flexCenter, gap: "40px", height: "100%" }}>
        <Typography>Features</Typography>
        <Typography>Pricing</Typography>
        <Typography>Blog</Typography>
        <Typography>Contact</Typography>
        <Box sx={{ ...styles.flexCenter, gap: "10px" }}>
          <Button
            onClick={() => navigate("/login")}
            sx={{
              color: "white",
              textTransform: "capitalize",
              bgcolor: "var(--dark-pr-clr)",
              ":hover": { bgcolor: "var(--dark-pr-clr)" },
              border: "1px solid var(--dark-pr-clr)",
              minWidth: "130px",
              borderRadius: "30px",
            }}
          >
            Login
          </Button>
          <Button
            sx={{
              bgcolor: "var(--primary-color)",
              ":hover": {
                bgcolor: "var(--primary-color)",
              },
              border: "1px solid white",
              minWidth: "150px",
              color: "white",
              borderRadius: "30px",
              textTransform: "capitalize",
            }}
          >
            Gets Started
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
