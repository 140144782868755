import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { submenuButton, addButton } from "../../utility/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid var(--primary-color)",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const styles = {
  root: {
    height: "calc(100vh - 200px)", // Set a fixed height for the container
    width: "100%", // Set a width for the container
    display: "flex",
    flexDirection: "column",
  },
  dataGrid: {
    flexGrow: 1,
  },
};

const headers = [
  "Employee Id",
  "Employee Name",
  "Office",
  "Department",
  "Status",
  "Disbursement Date",
  "Loan Amount",
  "Number Of Month",
  "Per Month Deduct Amount Wille Be",
  "Mail",
  "Action",
];

const Loan = () => {
  const navigate = useNavigate();
  const [office, setOffice] = useState([]);
  return (
    <Box>
      <Box
        sx={{ display: "flex", gap: "25px", justifyContent: "space-between" }}
      >
        <Typography variant="title" className="page-title">
          Loan Request
        </Typography>
      
      </Box>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Loan;
