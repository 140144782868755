import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

const AttendencePDF = ({ reportData }) => {
  const table = {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };

  const tableColName = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableCollast = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableCol = {
    width: "8.7%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  };
  const tableColfinal = {
    width: "10.5%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  };

  const tableCell = {
    margin: "3px",
    fontSize: "6.5px",
    minWidth: "30px", // set minimum width
    minHeight: "8px", // set minimum height
    flexWrap: "wrap", // allow content to wrap
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
  };

  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "7px",
  };

  const report = reportData?.attendances;
  // let firstReportData = report[0]?.date;

  const showShift = (shifts, prop) =>
    shifts?.map((data, index, array) => (
      <View
        key={index}
        style={{
          flexDirection: "row",
          borderBottom:
            index !== array.length - 1 ? "1px solid black" : undefined,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Text style={tableCell}>
          {prop === "checkIn" || prop === "checkOut" ? (
            <>
              {data[prop]
                ? moment(data[prop], "HH:mm:ss").format("h:mm:ss A")
                : "N/A"}
            </>
          ) : (
            <> {data[prop] || "N/A"}</>
          )}
        </Text>
      </View>
    ));

  return (
    <Document>
      <Page wrap={false}>
        <View
          style={{
            padding: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: "10px" }}>Fly Far International</Text>
          <Text style={{ fontSize: "8px" }}>
            Ka/9/A, Hazi Abdul Latif Mansion, Bashundhara Residential Road, Near
            of Jamuna Future Park, Dhaka
          </Text>
          <Text
            style={{
              borderBottomWidth: 0.5,
              borderBottomColor: "black",
              padding: "4px 10px",
              width: "100%",
            }}
          ></Text>
        </View>

        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: "10px",
              fontWeight: 500,
              padding: "5px 5px",
            }}
          >
            {report && report.length > 0 ? (
              <>
                {reportData?.employee?.firstname}{" "}
                {reportData?.employee?.lastname} Job Card Report From{"  "}
                {moment(report[0]?.date).format("DD MMMM YYYY")} To{" "}
                {moment(
                  reportData?.attendances[reportData?.attendances?.length - 1]
                    ?.date
                ).format("DD MMMM YYYY")}{" "}
              </>
            ) : (
              "N/A"
            )}
          </Text>
        </View>
        {/*****   Employee details table     *****/}
        <View style={{ padding: "10px", flexDirection: "row" }}>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>Employee Code </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Employee Name</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Join Date</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Job Status</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>115 </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>
                  {reportData?.employee?.firstname}{" "}
                  {reportData?.employee?.lastname}
                </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>
                  {reportData?.employee?.joiningDate}
                </Text>
              </View>
              <View style={tableColName}>
                <Text style={{ ...tableCell, textTransform: "capitalize" }}>
                  {reportData?.employee?.status}
                </Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>Designation </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Branch</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Divison</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Department</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableCollast}>
                <Text style={tableCell}>
                  {reportData?.employee?.designation}{" "}
                </Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>
                  {reportData?.employee?.branchName}
                </Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}> Static </Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>{reportData?.employee?.deptName}</Text>
              </View>
            </View>
          </View>
        </View>

        {/*****   Attendence details table     *****/}

        <View style={{ padding: "10px" }}>
          <View style={table}>
            <View style={{ flexDirection: "row" }}>
              <View style={tableCol}>
                <Text style={tableCell}>Attendance Date </Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Status</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Ratio</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Check In Status</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Check In</Text>
              </View>
              <View style={tableColfinal}>
                <Text style={tableCell}>Check In Remarks</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Check Out Status</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Check Out</Text>
              </View>
              <View style={tableColfinal}>
                <Text style={tableCell}>Check Out Remarks</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Shift Remarks</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Break Count</Text>
              </View>
              <View style={tableCol}>
                <Text style={tableCell}>Break Time</Text>
              </View>
              <View style={tableColfinal}>
                <Text style={tableCell}>Break Remarks</Text>
              </View>
            </View>

            {report &&
              report.map((data, index) => (
                <View key={index} style={{ flexDirection: "row" }}>
                  <View style={tableCol}>
                    <Text style={{ ...tableCell, textAlign: "start" }}>
                      {moment(data?.date).format("ddd Do MMM")}
                    </Text>
                  </View>
                  <View style={tableCol}>
                    <Text style={tableCell}>
                      {showShift(data?.shifts, "dayStatus")}
                    </Text>
                  </View>
                  <View style={tableCol}>
                    <Text style={tableCell}>
                      {data?.completeAttendance}/{data?.totalAttendances}
                    </Text>
                  </View>
                  <View style={tableCol}>
                    {showShift(data?.shifts, "checkInStatus")}
                  </View>
                  <View style={tableCol}>
                    {showShift(data?.shifts, "checkIn")}
                  </View>

                  <View style={tableColfinal}>
                    {showShift(data?.shifts, "checkInRemarks")}
                  </View>
                  <View style={tableCol}>
                    {showShift(data?.shifts, "checkOutStatus")}
                  </View>
                  <View style={tableCol}>
                    {showShift(data?.shifts, "checkOut")}
                  </View>
                  <View style={tableColfinal}>
                    {showShift(data?.shifts, "checkOutRemarks")}
                  </View>
                  <View style={tableCol}>
                    {showShift(data?.shifts, "shiftRemarks")}
                  </View>
                  <View style={tableCol}>
                    {showShift(data?.shifts, "breakCount")}
                  </View>
                  <View style={tableCol}>
                    {showShift(data?.shifts, "breakTime")}
                  </View>
                  <View style={tableColfinal}>
                    {showShift(data?.shifts, "breakRemarks")}
                  </View>
                </View>
              ))}
          </View>
        </View>
        {/*****   Attendence info table     *****/}
        <View style={{ padding: "10px", flexDirection: "row" }}>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>Present</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Late</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Leave</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Weekend</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Actual Duty Hour</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Total Days</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>{reportData?.summary?.present} </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>
                  {reportData?.summary?.lateCheckIns}
                </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>
                  {reportData?.summary?.leaveRequests}
                </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>
                  {reportData?.summary?.totalWeekends}
                </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>Absent </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Extra Delay</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Holidays</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Over Time</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Work Duty Hours</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Extra/Less Duty Hours</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableCollast}>
                <Text style={tableCell}> {reportData?.summary?.absent}</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}> Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}> Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}> Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}> Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}> Static</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ padding: "10px", flexDirection: "row" }}>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>Basic Salary</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Home Allowance</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Medical Allowance</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Transport Allowance</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Overtime</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Bonus</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Total</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>Static </Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Static</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableColName}>
                <Text style={tableCell}>Absent Days</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Late Days</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Advance Salary</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Loan Deduction</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Provident Fund</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Others</Text>
              </View>
              <View style={tableColName}>
                <Text style={tableCell}>Total Deduction</Text>
              </View>
            </View>
          </View>
          <View style={table}>
            <View style={{ flexDirection: "column" }}>
              <View style={tableCollast}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>Static</Text>
              </View>
              <View style={tableCollast}>
                <Text style={tableCell}>Static</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default AttendencePDF;
