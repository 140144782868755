import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const UpdateAttendanceStatus = ({
  currentDate,
  employeeId,
  employeeName,
  setOpenAttStaus,
}) => {
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [shifts, setShifts] = useState([]);
  const [currentShift, setCurrentShift] = useState(0);
  const [attendanceBody, setAttendanceBody] = useState({
    status: "accepted",
    time: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/attendances?employeeId=${employeeId}&date=${moment(
          currentDate
        ).format("YYYY-MM-DD")}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch attendance data");
        }

        const data = await response.json();

        if (data?.success === true && Array.isArray(data.data)) {
          setShifts(data.data);
          //   if (data.data.length > 0) {
          //     setCurrentShift(0); // Reset to the first shift after fetching new data
          //     setSelectedSublist(0); // Reset to the first shift after fetching new data
          //   }
        } else {
          console.error("Failed to fetch attendance data:", data);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error.message);
      }
    };

    fetchData();
  }, [currentDate, employeeId, token]);

  const handleSubmit = async (reqId) => {
    console.log(reqId);

    try {
      const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/attendance-requests/${reqId}`;

      const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(attendanceBody),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch attendance data");
      }

      const data = await response.json();

      // console.log(data);

      if (data?.success === true) {
        setOpenAttStaus(false);
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });
      } else {
        console.log(data);
        Swal.fire({
          icon: "error",
          title: data?.error?.message || "Something went wrong",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try again",
        }).then(function () {
          setOpenAttStaus(true);
          // navigate("/dashboard/employee");
        });
        throw new Error(data?.message);
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error.message);
    }
  };

  return (
    <Box>
      <Typography
        variant="title"
        className="page-title"
        sx={{ flex: 1, fontSize: "22px" }}
      >
        Attendance Status, {employeeName}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
          mt: 3,
        }}
      >
        {shifts?.map((_, i) => (
          <Button
            key={i}
            onClick={() => {
              setCurrentShift(i);
            }}
            sx={{
              bgcolor:
                i === currentShift ? "Var(--primary-color)" : "var(--bg-color)",
              ":hover": {
                bgcolor:
                  i === currentShift
                    ? "Var(--primary-color)"
                    : "Var(--primary-color)",
                color: "white",
              },
              textTransform: "capitalize",
              color: i === currentShift ? "white" : "var(--text-medium)",
              fontSize: "14px",
              height: "38px",
              width: "100%",
            }}
          >
            Shift {i + 1}
          </Button>
        ))}
      </Box>

      {shifts[currentShift]?.requests?.map((req, i) => (
        <Box key={i} mt={3}>
          <Typography sx={{ textTransform: "capitalize" }}>
            {req?.requestType.split("-").join(" ")}
          </Typography>

          <Grid container spacing={1}>
            <Grid item lg={4}>
              <Box sx={{ bgcolor: "#FAFAFA", p: 2, borderRadius: "4px" }}>
                <Typography
                  sx={{ color: "var(--primary-color)", fontSize: "15px" }}
                >
                  Requested Status
                </Typography>

                <Typography sx={{ fontSize: "12px" }}>
                  {req?.requestStatus || "N/A"}
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={4}>
              <Box sx={{ bgcolor: "#FAFAFA", p: 2, borderRadius: "4px" }}>
                <Typography
                  sx={{ color: "var(--primary-color)", fontSize: "15px" }}
                >
                  Requested Time
                </Typography>

                <Typography sx={{ fontSize: "12px" }}>
                  {moment(req?.requestedTime, "HH:mm:ss").format("h:mm:ss A") ||
                    "N/A"}
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={4}>
              <Box
                sx={{
                  bgcolor: "#FAFAFA",
                  p: 2,
                  borderRadius: "4px",
                }}
              >
                <Typography
                  sx={{ color: "var(--primary-color)", fontSize: "15px" }}
                >
                  Remarks
                </Typography>

                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "12px", color: "white" }}>
                      {req?.requestRemarks}
                    </Typography>
                  }
                  placement="bottom"
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    {req?.requestRemarks.slice(0, 40) + "..." || "N/A"}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>

            {req?.requestStatus === "pending" && (
              <>
                <Grid item lg={4}>
                  <Box
                    sx={{
                      bgcolor: "#FAFAFA",
                      p: 2,
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      sx={{ color: "var(--primary-color)", fontSize: "15px" }}
                    >
                      Select Approve Time
                    </Typography>

                    <input
                      value={attendanceBody?.time}
                      onChange={(e) => {
                        // console.log(e.target.value);
                        setAttendanceBody({
                          ...attendanceBody,
                          time: e.target.value + ":00",
                        });
                      }}
                      name="checkOut"
                      id="checkOut"
                      className="input-field"
                      type="time"
                      placeholder="Enter Check In"
                      required
                      style={{ width: "100%", backgroundColor: "transparent" }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={4}>
                  <Box
                    sx={{
                      bgcolor: "#FAFAFA",
                      p: 2,
                      borderRadius: "4px",
                      "& .MuiSelect-select": {
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Typography
                      sx={{ color: "var(--primary-color)", fontSize: "15px" }}
                    >
                      Select Approve Time
                    </Typography>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={attendanceBody.status}
                      label="Age"
                      sx={{ width: "100%", fontSize: "14px" }}
                    >
                      <MenuItem sx={{ fontSize: "14px" }} value={"accepted"}>
                        Accept
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value={"rejected"}>
                        Reject
                      </MenuItem>
                    </Select>
                  </Box>
                </Grid>

                <Grid item lg={4}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Button
                      key={i}
                      onClick={() => handleSubmit(req?.requestId)}
                      sx={{
                        bgcolor: "Var(--primary-color)",
                        ":hover": {
                          bgcolor: "Var(--primary-color)",
                        },
                        textTransform: "capitalize",
                        color: "white",
                        fontSize: "14px",
                        height: "38px",
                        width: "100%",
                      }}
                    >
                      Approve
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default UpdateAttendanceStatus;
