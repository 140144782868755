import { Box } from "@mui/material";
import React from "react";

const Others = ({ salaryData, handleGetSalaryValue, handleGetReason }) => {
  // console.log(salary)
  return (
    <Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Absent Deduction</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.LateDeductionByDay)}
          onChange={handleGetSalaryValue}
          name="LateDeductionByDay"
          className="input-field"
          type="number"
          placeholder="Enter Late Deduction By Day"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Late Deduction </span>
        </label>
        <input
          readOnly
          value={String(salaryData?.LateDeductionByTime)}
          onChange={handleGetSalaryValue}
          name="LateDeductionByTime"
          className="input-field"
          type="number"
          placeholder="Enter Late Deduction By Time"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Lunch Deduct</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.lunchDeduct)}
          onChange={handleGetSalaryValue}
          name="lunchDeduct"
          className="input-field"
          type="number"
          placeholder="Enter Lunch Deduct"
        />
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box className="input-container" sx={{ width: "33%" }}>
          <label
            htmlFor="salary"
            className="input-label"
            style={{ width: "70%" }}
          >
            <span>Food Allowance</span>
          </label>
          <input
            readOnly
            value={String(salaryData?.mealPrice * salaryData?.meals)}
            onChange={handleGetSalaryValue}
            name="foodAllowance"
            className="input-field"
            type="number"
            placeholder="Enter Meal Price"
          />
        </Box>
        <Box className="input-container" sx={{ width: "34%" }}>
          <label
            htmlFor="salary"
            className="input-label"
            style={{ width: "50%" }}
          >
            <span>Per Meal Price</span>
          </label>
          <input
            value={String(salaryData?.mealPrice )}
            onChange={handleGetSalaryValue}
            name="mealPrice"
            className="input-field"
            type="number"
            placeholder="Enter Meal Price"
          />
        </Box>
        <Box className="input-container" sx={{ width: "33%" }}>
          <label
            htmlFor="salary"
            className="input-label"
            style={{ width: "50%" }}
          >
            <span>Meals Count</span>
          </label>
          <input
            value={String(salaryData?.meals )}
            onChange={handleGetSalaryValue}
            name="meals"
            className="input-field"
            type="number"
            placeholder="Enter Meals Taken"
          />
        </Box>
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Advance Salary Deduction</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.advanceSalary)}
          onChange={handleGetSalaryValue}
          name="advanceSalary"
          className="input-field"
          type="number"
          placeholder="Enter Meal Price"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Loan Deduction</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.loanDeduction)}
          onChange={handleGetSalaryValue}
          name="loanDeduction"
          className="input-field"
          type="number"
          placeholder="Enter Meal Price"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Provident Fund</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.providentFund || 0)}
          onChange={handleGetSalaryValue}
          name="providentFund"
          className="input-field"
          type="number"
          placeholder="Enter Meal Price"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Loss Of Office Equipment</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.lossOfOfficeEquipment)}
          onChange={handleGetSalaryValue}
          name="lossOfOfficeEquipment"
          className="input-field"
          type="number"
          placeholder="Enter Loss Of Office Equipment"
        />
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box className="input-container" sx={{ width: "50%" }}>
          <label
            htmlFor="salary"
            className="input-label"
            style={{ width: "35%" }}
          >
            <span>Others Deductions</span>
          </label>
          <input
            value={String(salaryData?.extraDeduction)}
            onChange={handleGetSalaryValue}
            name="extraDeduction"
            className="input-field"
            type="number"
            placeholder="Enter Loss Of Office Equipment"
          />
        </Box>
        <Box className="input-container" sx={{ width: "50%" }}>
          <label
            htmlFor="salary"
            className="input-label"
            style={{ width: "35%" }}
          >
            <span>Others Deductions Reason</span>
          </label>
          <input
            value={salaryData?.extraDeductionReason}
            onChange={handleGetReason}
            name="extraDeductionReason"
            className="input-field"
            type="text"
            placeholder="Enter Others Deductions Reason"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Others;
