import { Navigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AdminPrivateRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const user = secureLocalStorage.getItem("admin-info");

  return !user?.user?.email ? (
    <Navigate to="/" state={{ from: location }} />
  ) : (
    children
  );
};

export default AdminPrivateRoute;
