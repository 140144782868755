import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { submenuButton, addButton } from "../../utility/styles";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import APILoader from "../../components/APILoader/APILoader";
import Swal from "sweetalert2";
import { ReactComponent as LeftArrowIcon } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svg/rightArrow.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const headers = [
  "Name",
  "Status",
  "Designations",
  "Basic Salary",
  "Home Allowance",
  "Medical Allowance",
  "Transportation Allowance",
  "Gross Salary",
  //   "Personal Contact",
  "Action",
];

const getCurrentMonth = (date) => {
  return date.toLocaleString("default", { month: "long" });
};

const ReviewSalary = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const [departments, setDepartments] = useState([]);
  const [deptId, setDeptId] = useState([]);
  const [employeeDept, setEmployeeDept] = useState([]);
  const [deptName, setDeptName] = useState("");
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const token = user?.accessToken;
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const token = user?.accessToken;
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/departments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setDepartments(data?.data);
        handleButtonClick(0, data?.data[0]?.id);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  useEffect(() => {
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries?deptId=${deptId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setEmployeeDept(
          data?.data.map((emp) => ({
            ...emp,
            isActive: emp.status === "active" ? true : false,
          }))
        );
        setDeptName(data?.data[0]?.deptName);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [deptId]);

  const handleButtonClick = (index, id, deptName) => {
    setSelectedButtonIndex(index);
    setDeptId(id);
    setDeptName(deptName);
  };

  const handleSingleEmployee = (id) => {
    navigate(`/dashboard/employeeDtails`, { state: { employeeId: id } });
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);

    const currentMonth = new Date().getMonth(); // Get current month

    if (
      newDate.getMonth() <= currentMonth ||
      newDate.getFullYear() < new Date().getFullYear()
    ) {
      setCurrentDate(newDate);

      // Call function to fetch attendance data for new month
      // fetchAttendanceData(newDate);
    } else {
      console.log("Cannot go beyond the current month.");
    }
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);

    // Call function to fetch attendance data for new month
    // fetchAttendanceData(newDate);
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        overflowY: "auto",
        "& .MuiAccordionSummary-root": {
          minHeight: "35px",
          margin: "0",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0",
        },
        "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
          {
            minHeight: "35px",
            margin: "0",
          },
        "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded ": {
          margin: "0",
        },
      }}
    >
      {/*------------- Page Title -------------*/}

      {departments.length > 0 ? (
        <Box
          sx={{ paddingBottom: "50px", position: "relative", height: "100%" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="title" className="page-title">
              Review Salary Month Of {getCurrentMonth(currentDate)},{" "}
              {currentDate.getFullYear()}
            </Typography>
            <Box className="date-change-container" sx={{ flex: "none" }}>
              <button
                className="previous-next-button"
                onClick={handlePreviousMonth}
                sx={{ width: "34px", height: "22px" }}
              >
                <LeftArrowIcon />
              </button>
              <button
                className="previous-next-button"
                onClick={handleNextMonth}
                sx={{ width: "34px", height: "22px" }}
              >
                <RightArrowIcon />
              </button>
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {departments.map((department, i) => (
                <Button
                  key={i}
                  sx={{
                    ...submenuButton,
                    ...(i === selectedButtonIndex && {
                      backgroundColor: "var(--primary-color)",
                      color: "#ffffff",
                      ":hover": {
                        bgcolor: "var(--primary-color)",
                      },
                      // width: "100px",
                    }),
                  }}
                  onClick={() =>
                    handleButtonClick(i, department.id, department.deptName)
                  }
                >
                  {department?.deptName}
                </Button>
              ))}
            </Box>
          </Box>

          <Accordion
            v
            sx={{ bgcolor: "var(--light-pr-clr)", borderRadius: "4px", mt: 2 }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                sx={{ fontSize: "14px", color: "var(--primary-color)" }}
              >
                {deptName} Salary Overview
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    flexWrap: "nowrap",
                    width: "100%",
                  }}
                >
                  {[
                    "Total Salary",
                    "Reviewed Salary",
                    "Pending Review Salary",
                    "Published Salary",
                    "Salary Approved",
                    "Pending Approval",
                    "Salary Rejected",
                  ].map((department, i) => (
                    <Box
                      key={i}
                      sx={{
                        bgcolor: "#fff",
                        p: 1,
                        borderRadius: "4px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        {department}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "var(--primary-color)",
                          }}
                        >
                          800
                        </span>
                      </Typography>

                      <Typography sx={{ fontSize: "12px" }}>
                        Total Gross Salary
                      </Typography>
                      <Typography
                        sx={{ fontSize: "18px", color: "var(--primary-color)" }}
                      >
                        BDT : 10000
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/*------------- Page Content -------------*/}
          <Box mt={3} pb={10}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1550 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeDept.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Button
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            color: "#ffffff",
                            ":hover": {
                              bgcolor: "var(--primary-color)",
                            },
                            width: "90%",
                            fontSize: "12px",
                          }}
                          onClick={() => handleSingleEmployee(data?.id)}
                        >
                          {data?.firstname} {data?.lastname}{" "}
                        </Button>
                      </TableCell>

                      <TableCell> {data?.status} </TableCell>
                      <TableCell> {data?.designation} </TableCell>
                      <TableCell> BDT {data?.basicSalary} </TableCell>
                      <TableCell> BDT {data?.homeAllowance} </TableCell>
                      <TableCell> BDT {data?.medicalAllowance} </TableCell>
                      <TableCell>BDT {data?.transportationAllowance}</TableCell>
                      <TableCell> BDT {data?.grossSalary} </TableCell>
                      {/* <TableCell> {data?.personalPhone} </TableCell> */}
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{
                            marginLeft: "10px",
                            background: "var(--primary-color)",
                            "&:hover": {
                              background: "var(--primary-color)",
                            },
                          }}
                          onClick={() => {
                            navigate("/dashboard/salaryBuild", {
                              state: {
                                currentDate,
                                locationData: {
                                  employeeId: data?.id,
                                },
                              },
                            });
                          }}
                        >
                          <Typography sx={{ fontSize: "12px", color: "white" }}>
                            Review
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              sx={{
                backgroundColor: "var(--primary-color)",
                color: "#ffffff",
                ":hover": {
                  bgcolor: "var(--primary-color)",
                },
                width: "85%",
                position: "fixed",
                bottom: "10px",
              }}
            >
              Publish {getCurrentMonth(currentDate)} {currentDate.getFullYear()}{" "}
              {deptName} Salary
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default ReviewSalary;
