import { Box } from "@mui/material";
import React from "react";

const Basics = ({ salaryData, handleGetSalaryValue }) => {
  // console.log(salary)
  return (
    <Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Home Allowance</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.homeAllowance)}
          onChange={handleGetSalaryValue}
          name="homeAllowance"
          className="input-field"
          type="number"
          placeholder="Enter Home Allowance"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Medical Allowance</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.medicalAllowance)}
          onChange={handleGetSalaryValue}
          name="medicalAllowance"
          className="input-field"
          type="number"
          placeholder="Enter Medical Allowance"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Transportation Allowance</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.transportationAllowance)}
          onChange={handleGetSalaryValue}
          name="transportationAllowance"
          className="input-field"
          type="number"
          placeholder="Enter Transportation Allowance"
        />
      </Box>

      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Food Allowance</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.foodAllowance)}
          onChange={handleGetSalaryValue}
          name="foodAllowance"
          className="input-field"
          type="number"
          placeholder="Enter Food Allowance"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Basic Salary</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.basicSalary)}
          onChange={handleGetSalaryValue}
          name="basicSalary"
          className="input-field"
          type="number"
          placeholder="Enter Basic Salary"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Gross Salary</span>
        </label>
        <input
          readOnly
          value={String(salaryData?.grossSalary)}
          onChange={handleGetSalaryValue}
          name="grossSalary"
          className="input-field"
          type="number"
          placeholder="Enter Gross Salary"
        />
      </Box>
    </Box>
  );
};

export default Basics;
