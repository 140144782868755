import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Zoom,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./Leave.css";
import { ReactComponent as EmailIcon } from "../../assets/svg/email.svg";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

const emailBoxInput = {
  display: "flex",
  borderBottom: "1px solid var(--border-color)",
  justifyContent: "space-between",
  py: 1,
  cursor: "pointer",
};

const actionButton = {
  border: "1px solid var(--text-medium)",
  borderRadius: "18px",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "var(--text-medium)",
    color: "white",
  },
  textTransform: "capitalize",
  width: "117px",
  height: "36px",
};

const headers = [
  "Leave Type",
  "Duration",
  "Emergency Pending Task To Complete",
  "On Going Work Responsible ",
  "Alternative Responsible Colleague Name ",
  "Emergency Contact Number",
];

const AttachmentBar = ({ onAttachmentChange }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    onAttachmentChange(file);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <div
          style={{
            background: "var(--primary-color)",
            width: "350px",
            height: "30px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
          onClick={handleAttachmentClick}
        >
          {fileName || "Attach file"}
        </div>
      </Grid>
      <Grid item>
        <input
          ref={fileInputRef}
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Grid>
    </Grid>
  );
};

const EmployeeLeaveRequest = () => {
  const location = useLocation();
  const leaveId = location?.state?.id;
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [leaveData, setLeaveData] = useState({});
  const [showChatBox, setShowChatBox] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ccMails, setCcMails] = useState([]);
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const emailRef = useRef();
  const subjectRef = useRef();

  const toggleChatBox = (value) => {
    setShowChatBox(value);
  };

  const handleAttachmentChange = (file) => {
    console.log("Attachment:", file);
  };

  // console.log(ccMails);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/leave-requests?id=${leaveId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        // console.log(data);
        setLeaveData(data?.data[0]);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [token, leaveId]);

  // console.log(leaveData);
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email) === false) {
      alert("Please enter a valid email");
      return;
    }

    if (email.trim() !== "") {
      setCcMails((prevCcMails) => [...prevCcMails, email.trim()]);
      setEmail("");
    }
  };

  const handleLeaveRequest = async () => {
    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/leave-requests/${leaveId}`;

    const body = {
      status: leaveData?.status,
      emails: ccMails.join(),
      comment,
    };

    console.log(body);
    console.log(url);

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      console.log(data);

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          // navigate("/dashboard/department");
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      }).then(function () {
        // navigate("/dashboard/department/addDepartment");
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "inherit",
          }}
        >
          <CircularProgress
            style={{
              color: "var(--primary-color)",
              height: "60px",
              width: "60px",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            ".MuiTableCell-root": {
              fontSize: "14px !important",
            },
            height: "100%",
          }}
        >
          <Typography variant="title" className="page-title">
            {leaveData?.subject}
          </Typography>

          <Box mt={5}>
            <Typography
              sx={{
                fontSize: "19px",
                color: "var(--text-dark)",
                fontWeight: 500,
              }}
            >
              {leaveData?.firstname} {leaveData?.lastname}
            </Typography>
            <Typography sx={{ color: "var(--primary-color)" }}>
              {leaveData?.designation},{" "}
              <span style={{ color: "var(--text-color)" }}>
                {leaveData?.deptName}
              </span>
            </Typography>
          </Box>

          <Box mt={5}>
            <Typography sx={{ fontSize: "16px" }}>Dear Admin,</Typography>
            <Typography sx={{ fontSize: "16px", mt: 0.5 }}>
              {leaveData?.description}
            </Typography>
          </Box>

          <Box mt={10}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{leaveData?.leaveType || "N/A"}</TableCell>
                    <TableCell>{leaveData?.dayDuration || "N/A"}</TableCell>
                    <TableCell>{leaveData?.pendingTasks || "N/A"}</TableCell>
                    <TableCell>{leaveData?.runningWorks || "N/A"}</TableCell>
                    <TableCell>
                      {leaveData?.alternativeResponsiblecolleague || "N/A"}
                    </TableCell>
                    <TableCell>
                      {leaveData?.emergencyContact || "N/A"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box mt={10} sx={{ display: "flex", gap: 2 }}>
            <Button
              onClick={() => toggleChatBox("Approved")}
              sx={{
                ...actionButton,
                bgcolor:
                  showChatBox === "Approved" ? "var(--text-medium)" : "none",
                color:
                  showChatBox === "Approved" ? "white" : "var(--text-dark)",
              }}
            >
              Approve
            </Button>
            <Button
              sx={{
                ...actionButton,
                bgcolor:
                  showChatBox === "Rejected" ? "var(--text-medium)" : "none",
                color:
                  showChatBox === "Rejected" ? "white" : "var(--text-dark)",
              }}
              onClick={() => toggleChatBox("Rejected")}
            >
              Reject
            </Button>
          </Box>

          <Box mt={15}>
            <Typography
              sx={{ fontSize: "15px", color: "var(--primary-color)", my: 2 }}
            >
              Attachment
            </Typography>
            <AttachmentBar onAttachmentChange={handleAttachmentChange} />
          </Box>

          <Box sx={{ position: "relative", bgcolor: "red", width: "100%" }}>
            <Box
              sx={{
                position: "absolute",
                right: "0",
                bottom: "0",
                width: "55px",
                height: "55px",
                backgroundColor: "var(--primary-color)",
                borderRadius: "50%",
                display: showChatBox ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => toggleChatBox("Approved")}
            >
              <EmailIcon
                width={"25px"}
                height={"25px"}
                style={{ fill: "white" }}
              />
            </Box>

            <Zoom
              in={!!showChatBox}
              style={{ transformOrigin: "bottom right" }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  width: "450px",
                  height: "420px",
                  bgcolor: "white",
                  zIndex: "100",
                  boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.14)",
                  borderRadius: "20px",
                  p: 3,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: "14px", mb: 1 }}>
                    Reply Email
                  </Typography>
                  <RemoveIcon
                    onClick={() => setShowChatBox(null)}
                    sx={{
                      color: "#4b4d4d",
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#dadce0",
                      },
                    }}
                  />
                </Box>

                <Box
                  // onClick={() => {
                  //   subjectRef.current.focus();
                  // }}
                  sx={emailBoxInput}
                >
                  <Typography sx={{ fontSize: "14px" }}>Subject</Typography>
                  <Box
                    sx={{
                      width: "85%",
                      display: "flex",
                      // flexWrap: "wrap",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", width: "max-content" }}>
                      {showChatBox} - {leaveData?.subject}
                    </Typography>
                    {/* <input
                      ref={subjectRef}
                      type="text"
                      style={{
                        width: "85%",
                        fontSize: "14px",
                      }}
                    /> */}
                  </Box>
                </Box>

                <Box
                  onClick={() => {
                    emailRef.current.focus();
                  }}
                  sx={emailBoxInput}
                >
                  <Typography sx={{ fontSize: "13px" }}>CC</Typography>
                  <Box
                    sx={{
                      width: "85%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    {ccMails?.map((mail, i) => (
                      <Typography
                        key={i}
                        sx={{
                          fontSize: "14px",
                          border: "1px solid var(--text-medium)",
                          p: "4px 5px 4px 10px",
                          borderRadius: "35px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {mail}
                        <CloseIcon
                          onClick={() =>
                            setCcMails((prev) =>
                              prev.filter((_, index) => index !== i)
                            )
                          }
                          sx={{ fontSize: "18px", cursor: "pointer" }}
                        />
                      </Typography>
                    ))}

                    <form
                      style={{
                        width: ccMails.length > 0 ? "120px" : "100%",
                      }}
                      onSubmit={handleSubmit}
                    >
                      <input
                        ref={emailRef}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          cursor: "pointer",
                          // border: "1px solid red",
                          width: ccMails.length > 0 ? "120px" : "100%",
                        }}
                      />
                    </form>
                  </Box>
                </Box>

                <Box>
                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={12}
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      resize: "none",
                    }}
                  />
                </Box>

                <Box
                  sx={{ height: "30px", position: "absolute", bottom: "15px" }}
                >
                  <Button
                    onClick={handleLeaveRequest}
                    sx={{
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      borderRadius: "18px",
                      fontSize: "12px",
                      "&:hover": {
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                      },
                      textTransform: "capitalize",
                      width: "97px",
                      height: "30px",
                    }}
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            </Zoom>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EmployeeLeaveRequest;
