import { Box, Typography } from "@mui/material";
import React from "react";

const PersonalInfoDetails = ({ employeeDetails }) => {
  const data = employeeDetails[0];
  return (
    <Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>First Name</span>
        </label>

        <Typography> {data?.firstname} </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Last Name</span>
        </label>
        <Typography> {data?.lastname} </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>DOB</span>
        </label>
        <Typography> {data?.dob} </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Blood Group</span>
        </label>
        <Typography> {data?.bloodGroup} </Typography>
      </Box>

      {/* <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>NID Image</span>
        </label>
        <Typography> {data?.firstname}  </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Profile Image</span>
        </label>
        <Typography> {data?.firstname}  </Typography>
      </Box> */}
      {/* <Box className="input-container">
      <label htmlFor="homeAllowance" className="input-label">
        <span>Passport No</span>
      </label>
      <input id="homeAllowance" className="input-field" type="text" />
    </Box> */}

      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Religion</span>
        </label>
        <Typography> {data?.religion} </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Nationality</span>
        </label>
        <Typography> {data?.nationality} </Typography>
      </Box>
    </Box>
  );
};

export default PersonalInfoDetails;
