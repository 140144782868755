import { Box, Typography } from "@mui/material";
import React from "react";

const OfficeInfoDetails = ({ employeeDetails }) => {
  const data = employeeDetails[0];
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Official Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span> Office</span>
        </label>
        <Typography>{data.branchName}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span> Department </span>
        </label>
        <Typography>{data.deptName}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Roaster</span>
        </label>
        <Typography>
          {[data.shift1Name, data.shift2Name, data.shift3Name, data.shift4Name]
            .filter((name) => name !== null)
            .join(", ")}
        </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Break Time in Min</span>
        </label>
        <Typography>{data.breakTime}</Typography>
      </Box>

      <Typography sx={{ mt: 6, mb: 2, fontWeight: 600 }}>Official</Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Designation</span>
        </label>
        <Typography>{data.designation}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Role</span>
        </label>
        <Typography>{data.role}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Joining Date</span>
        </label>
        <Typography>{data.joiningDate}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Employee Type</span>
        </label>
        <Typography>{data.employeeType}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Job Type</span>
        </label>

        <Typography>{data.jobType}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Job Location</span>
        </label>

        <Typography>{data.jobLocation}</Typography>
      </Box>

      <Typography sx={{ mt: 6, mb: 2, fontWeight: 600 }}>
        Contact Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Phone Number</span>
        </label>
        <Typography>{data.officialPhone}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Email</span>
        </label>
        <Typography>{data.email}</Typography>
      </Box>
    </Box>
  );
};

export default OfficeInfoDetails;
