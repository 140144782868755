import { Box, Typography } from "@mui/material";
import React from "react";

const EquipmentInfoDetails = ({ employeeDetails }) => {
  const data = employeeDetails[0];
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Equipment Allocation
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Allocate Laptop</span>
        </label>
        <Typography> {data.laptop === 1 ? "Yes" : "No"}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Mobile</span>
        </label>
        <Typography> {data.mobile === 1 ? "Yes" : "No"}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Mouse</span>
        </label>
        <Typography> {data.mouse === 1 ? "Yes" : "No"}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Keyboard</span>
        </label>
        <Typography> {data.keyboard === 1 ? "Yes" : "No"}</Typography>
      </Box>

      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Headphone </span>
        </label>
        <Typography> {data.headphone === 1 ? "Yes" : "No"}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Other Things </span>
        </label>
        <Typography> {data.otherAllocation === 1 ? "Yes" : "No"}</Typography>
      </Box>
    </Box>
  );
};

export default EquipmentInfoDetails;
