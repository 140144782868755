import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import OKIcon from "../../assets/gif/ok.gif";

const SetAbsentRules = () => {
  const [selectedShiftCount, setSelectedShiftCount] = useState("1");
  const [selectedShift, setSelectedShift] = useState("1");
  const [shift, setShift] = useState("");
  const [tempData, setTempData] = useState({});
  const shiftLabels = [
    "Shift 1 Deduct Amount",
    "Shift 2 Deduct Amount",
    "Shift 3 Deduct Amount",
    "Shift 4 Deduct Amount",
  ];
  const [deductionAmount, setDeductionAmount] = useState(0);
  const [absentRules, setAbsentRules] = useState({});
  const [showAddIcon, setShowAddIcon] = useState(false);
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  const handleShiftChange = (event) => {
    const selectedShift = event.target.value;
    setShift(selectedShift);
    const newShiftCount = parseInt(event.target.value);
    setSelectedShiftCount(parseInt(event.target.value));
    setSelectedShiftCount(newShiftCount);
  };

  const handleDeductAmountChange = (sc, s, d) => {
    console.log(sc, s, d);
    // setDeductionAmount(event.target.value);
    const ruleKey = `e${sc}s${s}`;
    const obj = { ...tempData };
    console.log(obj);
    obj[ruleKey] = Number(d);
    setTempData(obj);
  };

  const getDeductAmount = (sc, s) => {
    // setDeductionAmount(event.target.value);
    const ruleKey = `e${sc}s${s}`;
    return Number(tempData[ruleKey]);
  };

  const shiftButtons = Array.from(
    { length: selectedShiftCount },
    (_, i) => i + 1
  );

  const handleSave = () => {
    if (selectedShift && Number(deductionAmount) != NaN) {
      const ruleKey = `e${selectedShiftCount}s${selectedShift}`;
      const newRule = { [ruleKey]: parseInt(deductionAmount) }; // Parse deductionAmount to ensure it's a number
      const newRules = { ...absentRules, ...newRule };
      console.log(newRules);

      let obj = { ...tempData };
      obj[ruleKey] = Number(deductionAmount);
      setTempData({ ...obj });
      setShowAddIcon(true);
      // Swal.fire({
      //   icon: "success",
      //   title: "Success!",
      //   text: "Absent rule added successfully!",
      // });
    } else {
      alert("Please select a shift and enter a deduction amount.");
    }
  };
  console.log(tempData);
  const handleSubmit = () => {
    fetch(
      "https://flyfarerp-413411.de.r.appspot.com/api/company/salary-rules/absent",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tempData),
      }
    )
      .then((response) => {  
        if (!response.ok) {
          throw new Error("Failed to add new absent rule");
        }
        return response.json();
      })
      .then((data) => {
        // Handle success response
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Absent rule added successfully!",
        });
        console.log("New absent rule added successfully:", data);
      })
      .catch((error) => {
        console.error("Error adding new absent rule:", error.message);
      });
  };

  useEffect(() => {
    const ruleKey = `e${selectedShiftCount}s${selectedShift}`;
    if (tempData[ruleKey]) setDeductionAmount(tempData[ruleKey]);
    else setDeductionAmount(0);
  }, [selectedShift, selectedShiftCount]);

  useEffect(() => {
    setShowAddIcon(false);
  }, [selectedShift, selectedShiftCount, deductionAmount]);

 

  return (
    <Box>
      <Typography variant="title" className="page-title">
        Set New Absent Rules
      </Typography>
      <Box>
        <Box className="input-container">
          <label htmlFor="shift" className="input-label">
            <span>Select Shift Count</span>
          </label>
          <select
            type="text"
            name="shift"
            id="shift"
            onChange={handleShiftChange}
            placeholder="Select Shift"
            required
          >
            <option value="" disabled selected>
              Select Shift Here
            </option>
            {[1, 2, 3, 4].map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
        </Box>
        {/* 
        <Box className="input-container">
          <label htmlFor="shift" className="input-label">
            <span>Shift Number</span>
          </label>
          
          <Box sx={{ display: "flex", gap: 2 }}>
            {shiftButtons.map((shiftNum, index) => (
              <Button
                key={index}
                // sx={{
                //   color: selectedShiftCount === shiftNum ? "white" : "black",
                //   backgroundColor:
                //     selectedShiftCount === shiftNum
                //       ? "var(--primary-color)"
                //       : "var(--secondary-color)",
                // }}
                sx={{
                  color:
                    selectedShift === shiftNum.toString()
                      ? "white"
                      : "var(--primary-color)",

                  border: "2px solid var(--primary-color)",
                  backgroundColor:
                    selectedShift === shiftNum.toString()
                      ? "var(--primary-color)"
                      : "white",

                  "&:hover": {
                    backgroundColor:
                      selectedShift === shiftNum.toString()
                        ? "var(--primary-color)"
                        : "white",
                  },
                  borderRadius: "15px",
                }}
                onClick={() => handleShiftButtonClick(shiftNum.toString())}
              >
                Shift {shiftNum}
              </Button>
            ))}
          </Box>
        </Box> */}
        {Array.from(
          { length: selectedShiftCount },
          (_, index) => index + 1
        ).map((value) => {
          return (
            <Box className="input-container">
              <label htmlFor="homeAllowance" className="input-label">
                <span>Deduction percentage for Shift {value}</span>
              </label>
              <input
                name="deductAmount"
                id="deductAmount"
                value={getDeductAmount(selectedShiftCount, value)}
                // onChange={(e) => setDeductionAmount(e.target.value)}
                onChange={(e) =>
                  handleDeductAmountChange(
                    selectedShiftCount,
                    value,
                    e.target.value
                  )
                }
                className="input-field"
                type="number"
                placeholder="Deduction amount "
              />
            </Box>
          );
        })}

        {selectedShift && (
          <>
            {/* <Box className="input-container">
              <label htmlFor="homeAllowance" className="input-label">
                <span>Salary Deduction</span>
              </label>
              <select
                type="text"
                name="shift"
                id="shift"
                // onChange={handleShiftChange}
                placeholder="Select Category"
                required
              >
                <option value="" disabled>
                  Select Category
                </option>

                <option value="">From Basic Salary</option>
                <option value="">From Total Salary</option>
              </select>
            </Box> */}

            <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
              {/* <Button
                sx={{
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                  },
                  height: "40px",
                  width: "70px",
                }}
                onClick={handleSave}
              >
                {showAddIcon ? (
                  <img
                    src={OKIcon}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                ) : (
                  "Add"
                )}
              </Button> */}
              <Button
                sx={{
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                  },
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SetAbsentRules;
