import {
  Box,
  Button,
  Dialog,
  Grow,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReactComponent as LeftArrowIcon } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svg/rightArrow.svg";
import SingleAttendenceDetails from "./SingleAttendenceDetails";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AttendencePDF from "../../components/PDF/AttendencePDF";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import UpdateAttendanceStatus from "./UpdateAttendanceStatus";

import SingleOfficeXL from "./components/SingleOfficeXL";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const headers = [
  "Date",
  "Ratio",
  "Check In",
  "Check In Status",
  "Check In Remarks",
  "Check Out",
  "Check Out Status ",
  "Check Out Remarks ",
  "Break Time",
  "Break Time Remarks",
  "Attendance Status",
  "Details",
];

const getCurrentMonth = (date) => {
  return date.toLocaleString("default", { month: "long" });
};

const SingleStaffAttendence = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationData = location.state;

  const [currentDate, setCurrentDate] = useState(new Date());
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  const [open, setOpen] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [pickDate, setPickDate] = useState([]);
  const [openAttStaus, setOpenAttStaus] = useState(false);
  const [crrAttDate, setCrrAttDate] = useState("");
  const [openAttendSummary, setOpenAttendSummary] = useState(false);
  const [openDownloadOption, setOpenDownloadOption] = useState(false);
  const [csvAttendanceData, setCsvAttendanceData] = useState([]);

  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);

    const currentMonth = new Date().getMonth(); // Get current month

    if (
      newDate.getMonth() <= currentMonth ||
      newDate.getFullYear() < new Date().getFullYear()
    ) {
      setCurrentDate(newDate);
      setCurrentMonth(newDate.getMonth());
      // Call function to fetch attendance data for new month
      // fetchAttendanceData(newDate);
    } else {
      console.log("Cannot go beyond the current month.");
    }
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
    setCurrentMonth(newDate.getMonth());
    // Call function to fetch attendance data for new month
    // fetchAttendanceData(newDate);
  };

  const handleOpen = (employeId, employeeName, attendanceDate) => {
    setOpen(true);
    setEmployeeId(employeId);
    setEmployeeName(employeeName);
    setPickDate(attendanceDate);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/attendances/day-summary?employeeId=${
      locationData?.employeeId
    }&month=${currentMonth + 1}&year=${currentDate.getFullYear()}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === true) {
          let modifiedData;

          if (Array.isArray(data.data)) {
            modifiedData = data.data.map((d, idx) => {
              d.id = idx;
              return d;
            });
          }
          setAttendanceData(modifiedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [currentMonth, currentDate, locationData?.employeeId, token]);

  useEffect(() => {
    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/attendances/report/${
      locationData?.employeeId
    }/${currentDate.getFullYear()}/${currentMonth + 1}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === true) {
          setReportData(data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [currentMonth, currentDate, locationData?.employeeId, token]);

  // console.log(attendanceData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/attendances/monthly-report?employeeId=${
          locationData?.employeeId
        }&month=${currentMonth + 1}&year=${currentDate.getFullYear()}`;
        // const url = `http://192.168.1.103:5000/api/company/attendances/monthly-report?employeeId=${
        //   locationData?.employeeId
        // }&month=${currentMonth + 1}&year=${currentDate.getFullYear()}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch attendance data");
        }

        const data = await response.json();
        // console.log(data);
        setCsvAttendanceData(data.data);
      } catch (error) {
        console.error("Error fetching attendance data:", error.message);
      }
    };

    fetchData();
  }, [currentDate, locationData, token]);

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="title" className="page-title" sx={{ flex: 1 }}>
          Attendance Report of {locationData?.employeeName},{" "}
          {locationData?.deptName}, Month Of {getCurrentMonth(currentDate)},{" "}
          {currentDate.getFullYear()}
        </Typography>

        <Box className="date-change-container" sx={{ flex: "none" }}>
          <button
            className="previous-next-button"
            onClick={handlePreviousMonth}
            sx={{ width: "34px", height: "22px" }}
          >
            <LeftArrowIcon />
          </button>
          <button
            className="previous-next-button"
            onClick={handleNextMonth}
            sx={{ width: "34px", height: "22px" }}
          >
            <RightArrowIcon />
          </button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 4,
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Button
            onClick={() => setOpenDownloadOption(!openDownloadOption)}
            sx={{
              bgcolor: "var(--primary-color)",
              color: "white",
              textTransform: "capitalize",
              ":hover": {
                bgcolor: "var(--primary-color)",
              },
              fontSize: "12px",
            }}
          >
            Download Attendance Summary
          </Button>

          <Grow in={openDownloadOption}>
            <Box>
              <PDFDownloadLink
                document={<AttendencePDF reportData={reportData} />}
                fileName={`${
                  locationData?.employeeName
                } Attendence Report of ${getCurrentMonth(currentDate)}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "12px",
                        pt: 0.5,
                        pb: "2px",
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", mt: "2px" }}>
                        Download as PDF
                      </Typography>
                      <SystemUpdateAltIcon
                        style={{ color: "var(--primary-color)", width: "16px" }}
                      />
                    </Box>
                  )
                }
              </PDFDownloadLink>

              <SingleOfficeXL
                fileName={locationData?.employeeName}
                csvData={csvAttendanceData}
              />
            </Box>
          </Grow>
        </Box>

        <Button
          onClick={() => setOpenAttendSummary(!openAttendSummary)}
          sx={{
            bgcolor: "var(--primary-color)",
            color: "white",
            textTransform: "capitalize",
            ":hover": {
              bgcolor: "var(--primary-color)",
            },
            fontSize: "12px",
            height: "33px",
          }}
        >
          View Attendance Summary
        </Button>
      </Box>

      <Box mt={1}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {moment(data?.attendanceDate).format("DD MMMM, YYYY")}
                  </TableCell>
                  <TableCell>
                    {data?.completeAttendances}/{data?.shiftCount}
                  </TableCell>
                  <TableCell>
                    {data?.inTime
                      ? moment(data?.inTime, "hh:mm:ss").format("hh:mm:ss A")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{data?.inTimeStatus || "N/A"}</TableCell>
                  <TableCell>
                    {data?.inTimeRemarks ? (
                      <Tooltip
                        title={
                          <Typography sx={{ fontSize: "12px", color: "white" }}>
                            {data?.inTimeRemarks}
                          </Typography>
                        }
                        placement="bottom"
                      >
                        {data?.inTimeRemarks?.slice(0, 15) + "..."}
                      </Tooltip>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {data?.outTime
                      ? moment(data?.outTime, "hh:mm:ss").format("hh:mm:ss A")
                      : "N/A"}
                  </TableCell>

                  <TableCell>{data?.outTimeStatus || "N/A"}</TableCell>

                  <TableCell>
                    {data?.outTimeRemarks ? (
                      <Tooltip
                        title={
                          <Typography sx={{ fontSize: "12px", color: "white" }}>
                            {data?.outTimeRemarks}
                          </Typography>
                        }
                        placement="bottom"
                      >
                        {data?.outTimeRemarks?.slice(0, 15) + "..."}
                      </Tooltip>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {data?.outTimeRemarks ? (
                      <Tooltip
                        title={
                          <Typography sx={{ fontSize: "12px", color: "white" }}>
                            {data?.outTimeRemarks}
                          </Typography>
                        }
                        placement="bottom"
                      >
                        {data?.outTimeRemarks?.slice(0, 15) + "..."}
                      </Tooltip>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {data?.outTimeRemarks ? (
                      <Tooltip
                        title={
                          <Typography sx={{ fontSize: "12px", color: "white" }}>
                            {data?.outTimeRemarks}
                          </Typography>
                        }
                        placement="bottom"
                      >
                        {data?.outTimeRemarks?.slice(0, 15) + "..."}
                      </Tooltip>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {data?.attendanceRequestPending ? (
                      <Button
                        sx={{
                          bgcolor: "red",
                          color: "white",
                          fontSize: "12px",
                          width: "100%",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "red",
                          },
                        }}
                        onClick={() => {
                          setOpenAttStaus(true);
                          setEmployeeName(data?.employeeName);
                          setEmployeeId(data?.employeeId);
                          setPickDate(data?.attendanceDate);
                        }}
                      >
                        Waiting for approval
                      </Button>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "white",
                        fontSize: "12px",
                        width: "100%",
                        textTransform: "capitalize",
                        "&:hover": {
                          bgcolor: "var(--primary-color)",
                        },
                      }}
                      onClick={() => {
                        handleOpen(
                          data?.employeeId,
                          data?.employeeName,
                          data?.attendanceDate
                        );
                        setCrrAttDate(data?.attendanceDate);
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 3, minHeight: "450px" }}>
          <SingleAttendenceDetails
            currentDate={currentDate}
            employeeId={employeeId}
            employeeName={employeeName}
            setOpen={setOpen}
            crrAttDate={crrAttDate}
          />
        </Box>
      </Dialog>

      <Dialog
        open={openAttStaus}
        onClose={() => setOpenAttStaus(false)}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 3, minHeight: "450px" }}>
          <UpdateAttendanceStatus
            currentDate={pickDate}
            employeeId={employeeId}
            employeeName={employeeName}
            setOpenAttStaus={setOpenAttStaus}
          />
        </Box>
      </Dialog>

      <Dialog
        open={openAttendSummary}
        onClose={() => setOpenAttendSummary(false)}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 3, minHeight: "450px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Present</TableCell>
                <TableCell>{reportData?.summary?.present}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Absent</TableCell>
                <TableCell>{reportData?.summary?.absent}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pending Attendances</TableCell>
                <TableCell>{reportData?.summary?.pendingAttendances}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Leave Requests</TableCell>
                <TableCell>{reportData?.summary?.leaveRequests}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Weekends</TableCell>
                <TableCell>{reportData?.summary?.totalWeekends}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Late CheckIns</TableCell>
                <TableCell>{reportData?.summary?.lateCheckIns}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Late CheckIns</TableCell>
                <TableCell>{reportData?.summary?.lateCheckIns}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Early CheckOuts</TableCell>
                <TableCell>{reportData?.summary?.earlyCheckOuts}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Dialog>
    </Box>
  );
};

export default SingleStaffAttendence;
