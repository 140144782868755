import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AdminAuthentication = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loginAdmin = (loginData) => {
    setIsLoading(true);
    let body = JSON.stringify({
      email: loginData.email,
      password: loginData.password,
    });
    let url = `https://flyfarerp-413411.de.r.appspot.com/api/company/auth/login`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Login failed. Please check your credentials.");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data?.data?.user);
        setIsLoading(true);
        if (data?.success === true) {
          secureLocalStorage.setItem("admin-info", data?.data);
          navigate("/dashboard");
        } else {
          secureLocalStorage.removeItem("admin-info");
          setError(data?.error?.message);
        }
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(true);
      });
  };

  const logout = () => {
    secureLocalStorage.removeItem("admin-info");
    navigate("/");
  };

  return {
    loginAdmin,
    logout,
    isLoading,
    error,
  };
};

export default AdminAuthentication;
