import { Box, Button } from "@mui/material";
import React from "react";

const PersonalInfo = ({
  formData,
  handleFormChange,
  setFormData,
  handleNidImg,
  handleProfileImg,
}) => {
  const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  return (
    <Box>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>First Name</span>
        </label>
        <input
          value={formData.firstname}
          onChange={handleFormChange}
          name="firstname"
          id="firstname"
          className="input-field"
          type="text"
          placeholder="Enter Your First Name"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Last Name</span>
        </label>
        <input
          value={formData.lastname}
          onChange={handleFormChange}
          name="lastname"
          id="lastname"
          className="input-field"
          type="text"
          placeholder="Enter Your Last Name"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>DOB</span>
        </label>
        <input
          value={formData.dob}
          onChange={handleFormChange}
          name="dob"
          id="dob"
          className="input-field"
          type="date"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Blood Group</span>
        </label>
        <select
          name="bloodGroup"
          id="bloodGroup"
          value={formData?.bloodGroup}
          onChange={handleFormChange}
          required
        >
          <option value="">Select Blood Group</option>
          {bloodGroups.map((group, index) => (
            <option key={index} value={group.toLowerCase()}>
              {group}
            </option>
          ))}
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>NID Image</span>
        </label>
        <Box className="file-input-container">
          <input
            type="file"
            className="customFileType"
            id="nid"
            name="nid"
            accept="image/*"
            onChange={handleNidImg}
            style={{
              backgroundColor: "#f9f9f9",
              color: "#555",
              display: "flex",
              alignItems: "center",
              padding: "12px 10px",
              boxSizing: "border-box",
              width: "440px",
            }}
          />
        </Box>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Profile Image</span>
        </label>
        <input
          type="file"
          id="image"
          name="image"
          className="customFileType"
          accept="image/*"
          onChange={handleProfileImg}
          style={{
            backgroundColor: "#f9f9f9",
            color: "#555",
            display: "flex",
            alignItems: "center",
            padding: "12px 10px",
            boxSizing: "border-box",
            width: "440px",
          }}
        />
      </Box>
      {/* <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Passport No</span>
        </label>
        <input id="homeAllowance" className="input-field" type="text" />
      </Box> */}
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Religion</span>
        </label>
        <input
          value={formData.religion}
          onChange={handleFormChange}
          name="religion"
          id="religion"
          className="input-field"
          type="text"
          placeholder="Enter Your Religion"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Nationality</span>
        </label>
        <input
          value={formData.nationality}
          onChange={handleFormChange}
          name="nationality"
          id="nationality"
          className="input-field"
          type="text"
          placeholder="Enter Your Nationality"
        />
      </Box>
    </Box>
  );
};

export default PersonalInfo;
