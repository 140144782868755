import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";

const fetcher = async (endPoint, token) => {
  const response = await fetch(
    `https://flyfarerp-413411.de.r.appspot.com/api/company/${endPoint}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch branches");
  }

  const data = await response.json();
  return data?.data;
};

const fethcerAdd = async (endPoint, token, body) => {
  const response = await fetch(
    `https://flyfarerp-413411.de.r.appspot.com/api/company/salary-rules/${endPoint}`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  // if (!response.ok) {
  //   throw new Error("Failed to update rules");
  // }

  const data = await response.json();
  return data;
};

const button = {
  mt: 1.5,
  minWidth: "150px",
  fontSize: "14px",
  textTransform: "capitalize",
};

const chip = {
  text: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  btn: {
    fontSize: "14px",
    cursor: "pointer",
  },
};

const selectStyle = {
  fontSize: "14px",
  "& .MuiSelect-select": {
    py: "8px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
    borderColor: "transparent",
  },

  "& .MuiMenu-paper": {
    boxShadow: "10px 10px 10px black",
  },
};

const SetLateCountRules = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [branches, setBranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [lateType, setLateType] = useState("");
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [lateDayCount, setLateDayCount] = useState("");
  const [absentCount, setAbsentCount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedShiftCount, setSelectedShiftCount] = useState(1);
  const [penalties, setPenalties] = useState([
    {
      shiftCount: 1,
      shift: 1,
      timeAndPenalty: [
        {
          time: "",
          penalty: "",
        },
      ],
    },
  ]);

  useEffect(() => {
    const fetchBranch = async () => {
      try {
        const officeData = await fetcher("branches", token);
        setBranches(officeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBranch();
  }, [token]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const officeData = await fetcher("departments", token);
        setDepartments(officeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDepartments();
  }, [token]);

  const handleRemoveSelectedBranch = (e, value) => {
    e.stopPropagation();
    setSelectedBranch(selectedBranch.filter((b) => b.id !== value.id));
  };
  
  const handleRemoveSelectedDepts = (e, value) => {
    e.stopPropagation();
    setSelectedDepts(selectedDepts.filter((b) => b.id !== value.id));
  };

  const handleAddLateRuleByDay = async () => {
    const bodyDay = {
      branchIds: selectedBranch.map((b) => b.id),
      deptIds: selectedDepts.map((d) => d.id),
      days: lateDayCount,
      deduction: absentCount,
    };

    const bodyTime = {
      branchIds: selectedBranch.map((b) => b.id),
      deptIds: selectedDepts.map((d) => d.id),
      penalties: penalties.flatMap((item) =>
        item.timeAndPenalty.map((tp) => ({
          shiftCount: item.shiftCount,
          shift: item.shift,
          time: Number(tp.time),
          penalty: Number(tp.penalty),
        }))
      ),
    };

    try {
      setIsLoading(true);
      const data = await fethcerAdd(
        lateType === "Time" ? "late-time-rules" : "late-day-rules",
        token,
        lateType === "Time" ? bodyTime : bodyDay
      );

      if (data.success) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/lateCountRules");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: data?.error?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Ok",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTimeAndPenalty = (e, penaltyIndex, itemIndex) => {
    setPenalties(
      penalties.map((penalty, i) => {
        if (i === penaltyIndex) {
          return {
            ...penalty,
            timeAndPenalty: penalty.timeAndPenalty.map((item, index) => {
              if (index === itemIndex) {
                return {
                  ...item,
                  [e.target.name]: e.target.value,
                };
              }
              return item;
            }),
          };
        }
        return penalty;
      })
    );
  };

  // console.log(penalties);
  return (
    <Box
      sx={{
        ".MuiChip-root": {
          height: "27px",
          cursor: "auto",
        },
        ".MuiChip-label": {
          padding: "9px",
        },
      }}
    >
      <Typography variant="title" className="page-title">
        Set Late Rules
      </Typography>

      <Box mt={2}>
        {/* -------------Select Late Type------------- */}
        <Box className="input-container">
          <label htmlFor="office" className="input-label">
            Select Late Type
          </label>

          <Select
            onChange={(e) => setLateType(e.target.value)}
            value={lateType}
            sx={{ ...selectStyle, width: "30%" }}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <span style={{ color: "var(--text-medium)" }}>
                    Select Late Type Here
                  </span>
                );
              }
              return selected;
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={"Day"}>
              By Day
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={"Time"}>
              By Time
            </MenuItem>
          </Select>
        </Box>

        {/* -------------Select Office------------- */}
        <Box className="input-container">
          <label htmlFor="office" className="input-label">
            Select Office
          </label>
          <Select
            multiple
            displayEmpty
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
            sx={{ ...selectStyle, minWidth: "30%", width: "auto" }}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return (
                  <span style={{ color: "var(--text-medium)" }}>
                    Select Office Here
                  </span>
                );
              }

              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value.id}
                      label={
                        <span style={chip.text}>
                          {value?.branchName}{" "}
                          <ClearIcon
                            style={chip.btn}
                            onMouseDown={(e) =>
                              handleRemoveSelectedBranch(e, value)
                            }
                          />
                        </span>
                      }
                    />
                  ))}
                </Box>
              );
            }}
          >
            {branches.map((branch, i) => (
              <MenuItem sx={{ fontSize: "14px" }} key={i} value={branch}>
                {branch.branchName}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* -------------Select Department------------- */}
        <Box className="input-container">
          <label className="input-label">Select Department</label>

          <Select
            multiple
            displayEmpty
            value={selectedDepts}
            onChange={(e) => setSelectedDepts(e.target.value)}
            sx={{ ...selectStyle, minWidth: "30%", width: "auto" }}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return (
                  <span style={{ color: "var(--text-medium)" }}>
                    Select Department Here
                  </span>
                );
              }

              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value.id}
                      label={
                        <span style={chip.text}>
                          {value?.deptName}{" "}
                          <ClearIcon
                            style={chip.btn}
                            onMouseDown={(e) =>
                              handleRemoveSelectedDepts(e, value)
                            }
                          />
                        </span>
                      }
                    />
                  ))}
                </Box>
              );
            }}
          >
            {departments.map((dept, i) => (
              <MenuItem sx={{ fontSize: "14px" }} key={i} value={dept}>
                {dept.deptName}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {lateType === "Time" ? (
          <>
            {/* -------------Select Late Type------------- */}
            <Box className="input-container" mt={6}>
              <label htmlFor="office" className="input-label">
                Select Shift
              </label>

              <Select
                onChange={(e) => {
                  setSelectedShiftCount(e.target.value);
                  setPenalties(
                    Array.from({ length: e.target.value }, (_, index) => ({
                      shiftCount: e.target.value,
                      shift: index + 1,
                      timeAndPenalty: [
                        {
                          time: "",
                          penalty: "",
                        },
                      ],
                    }))
                  );
                }}
                value={selectedShiftCount}
                sx={{ ...selectStyle, width: "30%" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span style={{ color: "var(--text-medium)" }}>
                        Select Shift Here
                      </span>
                    );
                  }
                  return selected;
                }}
              >
                {[1, 2, 3, 4].map((count, i) => (
                  <MenuItem key={i} sx={{ fontSize: "14px" }} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {penalties?.map((penalty, i) => (
              <Box key={i} mt={6}>
                <Box className="input-container">
                  <label className="input-label">Shift Number</label>
                  <Typography
                    className="input-field"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: "14px",
                      fontSize: "14px",
                    }}
                  >
                    {penalty.shift}
                  </Typography>
                </Box>

                {penalty?.timeAndPenalty?.map((item, index, arr) => (
                  <React.Fragment key={index}>
                    <Box className="input-container">
                      <label className="input-label">Late time Range</label>
                      <input
                        name="time"
                        onChange={(e) => handleAddTimeAndPenalty(e, i, index)}
                        className="input-field"
                        type="number"
                        value={item?.time}
                        placeholder="Late Time Range"
                      />
                    </Box>
                    <Box className="input-container">
                      <label className="input-label">Deduct Amount</label>
                      <input
                        name="penalty"
                        onChange={(e) => handleAddTimeAndPenalty(e, i, index)}
                        className="input-field"
                        type="number"
                        value={item?.penalty}
                        placeholder="Deduct Amount"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "end",
                      }}
                    >
                      {arr.length > 1 && (
                        <Button
                          sx={{
                            bgcolor: "var(--text-dark)",
                            color: "white",
                            "&:hover": {
                              bgcolor: "var(--text-dark)",
                            },
                            ...button,
                          }}
                          onClick={() => {
                            setPenalties(
                              penalties.map((penalty, pI) => {
                                if (pI === i) {
                                  return {
                                    ...penalty,
                                    timeAndPenalty:
                                      penalty.timeAndPenalty.filter(
                                        (_, tI) => tI !== index
                                      ),
                                  };
                                }
                                return penalty;
                              })
                            );
                          }}
                        >
                          Remove
                        </Button>
                      )}

                      {arr.length - 1 === index && (
                        <Button
                          onClick={() => {
                            setPenalties(
                              penalties.map((penalty, pI) => {
                                if (
                                  pI === i &&
                                  penalty.timeAndPenalty.length < 4
                                ) {
                                  return {
                                    ...penalty,
                                    timeAndPenalty: [
                                      ...penalty.timeAndPenalty,
                                      { time: "", penalty: "" },
                                    ],
                                  };
                                }
                                return penalty;
                              })
                            );
                          }}
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "white",
                            "&:hover": {
                              bgcolor: "var(--primary-color)",
                            },
                            ...button,
                          }}
                        >
                          Add Another
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            ))}

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "end",
                mt: 6,
              }}
            >
              <Button
                disabled={isLoading}
                onClick={handleAddLateRuleByDay}
                sx={{
                  bgcolor: "var(--primary-color)",
                  color: "white",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                  },
                  ...button,
                  height: "35px",
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "white",
                    }}
                  />
                ) : (
                  "Add Rule"
                )}
              </Button>
            </Box>
          </>
        ) : lateType === "Day" ? (
          <Box mt={6}>
            <Box className="input-container">
              <label htmlFor="office" className="input-label">
                Select Late Day Count
              </label>

              <Select
                onChange={(e) => setLateDayCount(e.target.value)}
                value={lateDayCount}
                sx={{ ...selectStyle, width: "30%" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span style={{ color: "var(--text-medium)" }}>
                        Select Late Day Count Here
                      </span>
                    );
                  }
                  return selected;
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day, i) => (
                  <MenuItem key={i} sx={{ fontSize: "14px" }} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box className="input-container">
              <label htmlFor="office" className="input-label">
                Absent Count
              </label>

              <Select
                onChange={(e) => setAbsentCount(e.target.value)}
                value={absentCount}
                sx={{ ...selectStyle, width: "30%" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span style={{ color: "var(--text-medium)" }}>
                        Absent Count Here
                      </span>
                    );
                  }
                  return selected;
                }}
              >
                {[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((day, i) => (
                  <MenuItem key={i} sx={{ fontSize: "14px" }} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
              <Button
                disabled={isLoading}
                onClick={handleAddLateRuleByDay}
                sx={{
                  bgcolor: "var(--primary-color)",
                  color: "white",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                  },
                  ...button,
                  height: "35px",
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "white",
                    }}
                  />
                ) : (
                  "Add Rule"
                )}
              </Button>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default SetLateCountRules;
