import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Zoom,
  Tooltip,
  FormControl,
  styled,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { addButton } from "../../utility/styles";
import moment from "moment";
import APILoader from "../../components/APILoader/APILoader";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const CustomSelect = styled(Select)({
  width: "100%", // Initial fixed width
  minWidth: "200px", // Minimum width to prevent shrinking
  "&.MuiOutlinedInput-root": {
    border: "none", // Remove border from root element
  },
  "& .MuiSelect-select": {
    display: "flex",
    flexGrow: 1,
  },
});

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "black",
  "&.Mui-selected": {
    backgroundColor: "var(--primary-color)", // Set background color for selected items
    color: "white",
    "&:hover": {
      color: "white", // Change text color on hover
      backgroundColor: "var(--primary-color)",
    },
  },
}));

const categoryOptions = [
  {
    name: "House Rules",
    value: "house-rules",
  },
  {
    name: "Birthday",
    value: "birthday-on-board",
  },
  {
    name: "Promotions",
    value: "promotions",
  },
  {
    name: "Holidays",
    value: "holidays",
  },
  {
    name: "Attendance Rules",
    value: "attendance-rules",
  },
  {
    name: "Celebrations",
    value: "celebrations",
  },
  {
    name: "Festival",
    value: "festival",
  },
  {
    name: "Cultural Program",
    value: "cultural-program",
  },
  {
    name: "House Party",
    value: "house-party",
  },
  {
    name: "Others",
    value: "others",
  },
];

const headers = [
  "Subject",
  "Notice Type",
  "Description",
  "Created At",
  "Updated At",
];

const Notice = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const navigate = useNavigate();
  const token = user?.accessToken;
  const [noticeData, setNoticeData] = useState([]);
  const [singleNotice, setSingleNotice] = useState({});
  const [showModal, setShowModal] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/notices`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setNoticeData(data?.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [token]);

  const handleShowNoticeDetails = (id) => {
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/notices/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // setNoticeData(data?.data);
        setSingleNotice(data?.data[0]);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleChangeUpdateData = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateNotice = (id) => {
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/notices/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          description: updateData?.description,
          subject: updateData.subject,
          noticeType: updateData?.noticeType,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data?.success) {
          setShowUpdateModal(false);
          Swal.fire({
            icon: "success",
            title: data?.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data?.error?.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Try again",
          });
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  // console.log(updateData);

  return (
    <Box>
      {noticeData.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "25px",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="title" className="page-title">
              Notice
            </Typography>
            {user?.user?.role !== "manager" && (
              <Button
                onClick={() => navigate("/dashboard/addNotice")}
                sx={addButton}
              >
                Add Notice
              </Button>
            )}
          </Box>

          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                    <TableCell sx={{ textAlign: "center" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {noticeData?.map((notice, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Tooltip
                          title={
                            <Typography
                              sx={{ color: "white", fontSize: "14px" }}
                            >
                              {notice?.subject || "N/A"}
                            </Typography>
                          }
                        >
                          {notice?.subject.slice(0, 50) || "N/A"}
                        </Tooltip>
                      </TableCell>
                      <TableCell>{notice?.noticeType}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <Typography
                              sx={{ color: "white", fontSize: "14px" }}
                            >
                              {notice?.description || "N/A"}
                            </Typography>
                          }
                        >
                          {notice?.description.slice(0, 50) || "N/A"}
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {moment(notice?.createdAt).format(
                          "DD MMM YYYY hh:mm:ss"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(notice?.updatedAt).format(
                          "DD MMM YYYY hh:mm:ss"
                        )}
                      </TableCell>

                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {user?.user?.role !== "manager" && (
                            <Button
                              onClick={() => {
                                setShowUpdateModal(true);
                                setUpdateData(notice);
                              }}
                              variant="contained"
                              sx={{
                                marginLeft: "10px",
                                bgcolor: "var(--primary-color)",
                                ":hover": { bgcolor: "var(--primary-color)" },
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "12px", color: "white" }}
                              >
                                Update
                              </Typography>
                            </Button>
                          )}

                          <Button
                            onClick={() => handleShowNoticeDetails(notice?.id)}
                            variant="contained"
                            sx={{
                              marginLeft: "10px",
                              bgcolor: "var(--text-medium)",
                              ":hover": { bgcolor: "var(--text-medium)" },
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "white" }}
                            >
                              View
                            </Typography>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}

      <Dialog
        // open={true}
        open={showModal}
        onClose={() => setShowModal(null)}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 2, minHeight: "450px" }}>
          <Typography sx={{ fontSize: "17px", textTransform: "capitalize" }}>
            Subject: {singleNotice?.subject}
          </Typography>

          <Typography sx={{ fontSize: "17px", textTransform: "capitalize" }}>
            Notice Type: {singleNotice?.noticeType}
          </Typography>

          <Typography sx={{ fontSize: "17px", textTransform: "capitalize" }}>
            Branches:{" "}
            {singleNotice?.branches?.map((branch, i) => (
              <span key={i}>{branch?.branchName}, </span>
            ))}
          </Typography>

          <Typography sx={{ fontSize: "17px", textTransform: "capitalize" }}>
            Departments:{" "}
            {singleNotice?.depts?.map((branch, i) => (
              <span key={i}>{branch?.deptName}, </span>
            ))}
          </Typography>

          <Typography sx={{ fontSize: "17px", textTransform: "capitalize" }}>
            Description: {singleNotice?.description}
          </Typography>
        </Box>
      </Dialog>

      <Dialog
        // open={true}
        open={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ height: "350px", p: 4 }}>
          <Typography
            variant="title"
            className="page-title"
            sx={{ mb: 2, fontSize: "20px" }}
          >
            Update Notice
          </Typography>

          <Box className="input-container">
            <label htmlFor="noticeType" className="input-label">
              <span>Select Categories</span>
            </label>

            <select
              name="noticeType"
              id="noticeType"
              onChange={handleChangeUpdateData}
              value={updateData?.noticeType}
              style={{ fontSize: "14px" }}
            >
              {categoryOptions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </Box>

          <Box className="input-container">
            <label htmlFor="salary" className="input-label">
              <span>Enter Notice Title</span>
            </label>
            <input
              name="subject"
              id="subject"
              value={updateData?.subject}
              onChange={handleChangeUpdateData}
              className="input-field"
              type="text"
              placeholder="Notice Title"
              required
            />
          </Box>

          <Box className="input-container">
            <label htmlFor="salary" className="input-label">
              <span>Enter Description</span>
            </label>
            <input
              name="description"
              id="description"
              value={updateData?.description}
              onChange={handleChangeUpdateData}
              className="input-field"
              type="text"
              placeholder="Notice Description"
              required
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              sx={{
                color: "#fff",
                background: "var(--primary-color)",
                mt: 4,
                minWidth: "100px",
                "&:hover": { background: "var(--primary-color)" },
              }}
              onClick={() => handleUpdateNotice(updateData?.id)}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Notice;
