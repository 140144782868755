import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Zoom,
  Dialog,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const ActivityLog = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const [activityLogs, setActivityLogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const token = user?.accessToken;

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/logs`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        // console.log(data);

        // const uniqueTasks = Object.values(
        //   data?.data.reduce((acc, obj) => {
        //     if (!acc[obj.task]) {
        //       acc[obj.task] = obj;
        //     }
        //     return acc;
        //   }, {})
        // );
        // console.log(uniqueTasks);
        // console.log(data?.data)
        // setActivityLogs(uniqueTasks);
        setActivityLogs(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [token]);
  return (
    <Box>
      <Typography variant="title" className="page-title">
        Activity Log
      </Typography>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "15%" }}>Task</TableCell>
                <TableCell sx={{ width: "10%" }}>User Role</TableCell>
                <TableCell sx={{ width: "5%" }}>IP</TableCell>
                <TableCell>Details</TableCell>
                <TableCell sx={{ width: "10%" }}>Action</TableCell>
                <TableCell sx={{ width: "10%" }}>Module</TableCell>
                <TableCell sx={{ width: "10%" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityLogs.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data?.task}</TableCell>
                  <TableCell> {data?.userRole} </TableCell>
                  <TableCell>{data?.ip}</TableCell>
                  <TableCell>
                    <Tooltip
                      TransitionComponent={Zoom}
                      arrow
                      title={
                        <span style={{ fontSize: "14px" }}>
                          {data?.details}
                        </span>
                      }
                    >
                      {data?.details?.slice(0, 70)}
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {data?.actions?.map((acc, i) => (
                      <React.Fragment key={i}>{acc?.action}</React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell>
                    {data?.actions?.map((acc, i) => (
                      <React.Fragment key={i}>{acc?.module}</React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        setOpen(!open);
                        setDetailsData(data);
                      }}
                      variant="contained"
                      sx={{
                        bgcolor: "var(--primary-color)",
                        ":hover": {
                          bgcolor: "var(--primary-color)",
                        },
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", color: "white" }}>
                        View {index + 1}
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        maxWidth="lg"
        fullWidth
      >
        <Box
          sx={{
            p: 3,
            ".MuiTable-root": {
              border: "none",
            },
            ".MuiTableCell-root": {
              // border: "1px solid var(--border-color)",
              // borderBottom: "none",
              padding: "0px !important",
            },
          }}
        >
          <Typography variant="title" className="page-title">
            Activity Log Details (
            {detailsData?.actions?.map((acc, i) => (
              <React.Fragment key={i}>{acc?.module}</React.Fragment>
            ))}
            )
          </Typography>

          {/* <TableContainer sx={{ marginTop: "20px" }}>
            <Table aria-label="nested table">
              <TableHead>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell>Module</TableCell>
                  <TableCell>Affected Rows</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailsData?.actions?.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>{item?.action}</TableCell>
                      <TableCell>{item?.module}</TableCell>
                      <TableCell>{item?.affectedRows}</TableCell>
                    </TableRow>
                    {item?.modifications && item?.modifications?.length > 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <TableContainer>
                            <Table aria-label="nested table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>For</TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    New Data
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    Old Data
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item?.modifications?.map((mod, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell>{mod?.for?.name}</TableCell>
                                    <TableCell>
                                      <TableContainer>
                                        <Table aria-label="nested table">
                                          <TableHead>
                                            <TableRow>
                                              {Object.keys(mod?.newData).map(
                                                (key, i) => (
                                                  <TableCell key={i}>
                                                    {key}
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              {Object.values(mod?.newData).map(
                                                (key, i) => (
                                                  <TableCell key={i}>
                                                    {key}
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </TableCell>
                                    <TableCell>
                                      <TableContainer>
                                        <Table aria-label="nested table">
                                          <TableHead>
                                            <TableRow>
                                              {Object.keys(mod?.oldData).map(
                                                (key, i) => (
                                                  <TableCell key={i}>
                                                    {key}
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              {Object.values(mod?.oldData).map(
                                                (key, i) => (
                                                  <TableCell key={i}>
                                                    {key}
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}

          <TableContainer sx={{ marginTop: "20px" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "50%%" }}>New Data</TableCell>
                  <TableCell sx={{ width: "50%%" }}>Old Data</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {detailsData?.actions?.map((item, index) => {
                  //   console.log(index);
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ width: "50%" }}>
                        {item?.modifications?.map((mod, i) => {
                          return (
                            <React.Fragment key={i}>
                              {mod?.newData && (
                                <>
                                  {Object?.entries(mod?.newData)?.map(
                                    ([key, value], inK, arr) => {
                                      // console.log(key, value);

                                      let isKeySame;
                                      let isValueSame;

                                      if (typeof value === "string") {
                                        isKeySame =
                                          mod?.oldData &&
                                          Object.keys(mod?.oldData)[inK] ===
                                            key;
                                        isValueSame =
                                          mod?.oldData &&
                                          Object.values(mod?.oldData)[inK] !==
                                            value;
                                      }
                                      // console.log(isKeySame, key);
                                      return (
                                        <Box
                                          key={inK}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            borderBottom:
                                              inK === arr.length - 1
                                                ? "none"
                                                : "1px solid var(--border-color)",
                                            minHeight: "50px",
                                            justifyContent: "space-between",
                                            // height: "100%",
                                            py: 0.5,
                                            // bgcolor: "blue",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              textTransform: "capitalize",
                                              width: "20%",
                                              fontSize: "14px",
                                              color:
                                                isKeySame && isValueSame
                                                  ? "red"
                                                  : "",
                                            }}
                                          >
                                            {key}
                                          </Typography>

                                          {key === "image" || key === "nid" ? (
                                            <Box
                                              sx={{
                                                width: "77%",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: "45px",
                                                  height: "45px",
                                                }}
                                                src={`${value}`}
                                                alt="image"
                                              />
                                            </Box>
                                          ) : (
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                width: "75%",
                                                pr: 1.5,
                                                color:
                                                  isKeySame && isValueSame
                                                    ? "red"
                                                    : "",
                                              }}
                                              noWrap={
                                                key === "image" ||
                                                key === "password"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {typeof value === "string" ? (
                                                value
                                              ) : typeof value === "object" ? (
                                                <span>
                                                  {value?.map((val, index) => (
                                                    <span key={index}>
                                                      {val?.deptName &&
                                                        val?.deptName}{" "}
                                                      {val?.branchName &&
                                                        val?.branchName}
                                                    </span>
                                                  ))}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </Typography>
                                          )}
                                        </Box>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableCell>
                      <TableCell sx={{ width: "50%" }}>
                        {item?.modifications?.map((mod, i) => {
                          return (
                            <React.Fragment key={i}>
                              {mod?.oldData && (
                                <>
                                  {Object?.entries(mod?.oldData)?.map(
                                    ([key, value], inK, arr) => (
                                      <Box
                                        key={inK}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          borderBottom:
                                            inK === arr.length - 1
                                              ? "none"
                                              : "1px solid var(--border-color)",
                                          minHeight: "50px",
                                          justifyContent: "space-between",
                                          // height: "100%",
                                          py: 0.5,
                                          // bgcolor: "blue",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textTransform: "capitalize",
                                            width: "20%",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {key}
                                        </Typography>

                                        {key === "image" || key === "nid" ? (
                                          <Box
                                            sx={{
                                              width: "77%",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "45px",
                                                height: "45px",
                                              }}
                                              src={`${value}`}
                                              alt="image"
                                            />
                                          </Box>
                                        ) : (
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              width: "75%",
                                              pr: 1.5,
                                            }}
                                            noWrap={
                                              key === "image" ||
                                              key === "password"
                                                ? true
                                                : false
                                            }
                                          >
                                            {typeof value === "string" ? (
                                              value
                                            ) : typeof value === "object" ? (
                                              <span>
                                                {value?.map((val, index) => (
                                                  <span key={index}>
                                                    {val?.deptName &&
                                                      val?.deptName}{" "}
                                                    {val?.branchName &&
                                                      val?.branchName}
                                                  </span>
                                                ))}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </Typography>
                                        )}
                                      </Box>
                                    )
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ActivityLog;
