import { Box, Typography } from "@mui/material";
import React from "react";

const SalaryInfoDetails = ({ employeeDetails }) => {
  const data = employeeDetails[0];
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Salary Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Basic Salary</span>
        </label>
        <Typography>{data.basicSalary}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Home Allowance</span>
        </label>
        <Typography>{data.homeAllowance}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Medical Allowance</span>
        </label>
        <Typography>{data.medicalAllowance}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Transportation Allowance</span>
        </label>
        <Typography>{data.transportationAllowance}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Casual Leave</span>
        </label>
        <Typography>{data.casualLeave}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Sick Leave</span>
        </label>
        <Typography>{data.sickLeave}</Typography>
      </Box>

      <Box className="input-container" mt={4}>
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Advance Salary </span>
        </label>
        <Typography>
          {data.advancedSalaryEligibility === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Loan </span>
        </label>
        <Typography>
          {data.loanSalaryEligibility === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Casual Leave </span>
        </label>
        <Typography>
          {data.casualLeaveEligibility === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Having Lunch</span>
        </label>
        <Typography>{data.havingLunch === 1 ? "Yes" : "No"}</Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Overtime </span>
        </label>
        <Typography>{data.overtimeEligibility === 1 ? "Yes" : "No"}</Typography>
      </Box>
    </Box>
  );
};

export default SalaryInfoDetails;
