import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const AddOffice = () => {
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const user = secureLocalStorage.getItem("admin-info");

  const token = user?.accessToken;

  console.log(token);

  console.log(user);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url =
      "https://flyfarerp-413411.de.r.appspot.com/api/company/branches";
    const body = JSON.stringify({
      branchName: formInfo.branchName,
      address: formInfo.address,
      minLatitude: parseFloat(formInfo.minLatitude),
      maxLatitude: parseFloat(formInfo.maxLatitude),
      minLongitude: parseFloat(formInfo.minLongitude),
      maxLongitude: parseFloat(formInfo.maxLongitude),
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/office");
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      }).then(function () {
        navigate("/dashboard/office");
      });
    }
  };
  return (
    <Box>
      <Box ml={1}>
        <Typography variant="title" className="page-title">
          Add Office
        </Typography>
      </Box>

      <Box sx={{ p: 1, mt: 2 }}>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Branch Name</span>
          </label>
          <input
            value={formInfo?.branchName}
            onChange={handleChange}
            name="branchName"
            id="branchName"
            className="input-field"
            type="text"
            placeholder="Branch Name"
            required
          />
        </Box>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Address</span>
          </label>
          <input
            value={formInfo?.address}
            onChange={handleChange}
            name="address"
            id="address"
            className="input-field"
            type="text"
            placeholder="Address"
            required
          />
        </Box>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Min Latitude </span>
          </label>
          <input
            name="minLatitude"
            id="minLatitude"
            value={formInfo?.minLatitude}
            onChange={handleChange}
            className="input-field"
            type="number"
            placeholder="Min Latitude"
            required
          />
        </Box>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Max Latitude </span>
          </label>
          <input
            name="maxLatitude"
            id="maxLatitude"
            value={formInfo?.maxLatitude}
            onChange={handleChange}
            className="input-field"
            type="number"
            placeholder="Max Latitude"
            required
          />
        </Box>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Min Longitude </span>
          </label>
          <input
            name="minLongitude"
            id="minLongitude"
            value={formInfo?.minLongitude}
            onChange={handleChange}
            className="input-field"
            type="number"
            placeholder="Min Longitude"
            required
          />
        </Box>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Max Longitude </span>
          </label>
          <input
            name="maxLongitude"
            id="maxLongitude"
            value={formInfo?.maxLongitude}
            onChange={handleChange}
            className="input-field"
            type="number"
            placeholder="Max Longitude"
            required
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            sx={{
              color: "#fff",
              background: "var(--primary-color)",
              mt: 2,
              "&:hover": {
                background: "var(--primary-color)",
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddOffice;
