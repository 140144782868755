import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment/moment";
import React from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const roasterHeader = [
  "Name",
  "Check In",
  "Check Out",
  "Buffer Time",
  "Branch Name",
  "Department Name",
  "Break Time",
  "Created By",
  "Role",
  "Created At",
];

const PendingRoaster = ({ singleReq, setIsLoading }) => {
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  const handleApproveReject = (status, id) => {
    Swal.fire({
      icon: "warning",
      title: `Are you sure you want to ${status} this roaster ?`,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Ok",
    }).then(async function (res) {
      if (res.isConfirmed) {
        setIsLoading(true);
        await fetch(
          `https://flyfarerp-413411.de.r.appspot.com/api/v2/company/roasters/status/${id}`,
          {
            method: "PATCH",
            body: JSON.stringify({
              status: status === "approve" ? "active" : "rejected",
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setIsLoading(false);
            if (data.success) {
              Swal.fire({
                icon: "success",
                title: data.message,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                window.location.reload();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: data?.error?.message,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {roasterHeader.map((header, i, arr) => (
                <TableCell
                  sx={{ textAlign: i === arr?.length - 1 && "center" }}
                  key={i}
                >
                  {header}
                </TableCell>
              ))}

              {user?.user?.role !== "manager" && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {singleReq?.map((roaster, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{roaster?.roasterName}</TableCell>
                  <TableCell>
                    {moment(roaster?.checkIn, "HH:mm:ss").format("hh:mm A")}
                  </TableCell>
                  <TableCell>
                    {moment(roaster?.checkOut, "HH:mm:ss").format("hh:mm A")}
                  </TableCell>
                  <TableCell>{roaster?.bufferTime} Min</TableCell>
                  <TableCell>{roaster?.branchName}</TableCell>
                  <TableCell>{roaster?.deptName}</TableCell>
                  <TableCell>{roaster?.breakTime} Min</TableCell>
                  <TableCell>
                    {roaster?.createByUser?.firstname}{" "}
                    {roaster?.createByUser?.lastname}{" "}
                  </TableCell>
                  <TableCell>{roaster?.createByUser?.role} </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {moment(roaster?.createdAt).format(
                      "hh:mm:ss A DD MMM YYYY"
                    )}
                  </TableCell>

                  {user?.user?.role !== "manager" && (
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        sx={{
                          marginLeft: "10px",
                          background: "var(--primary-color)",
                          "&:hover": {
                            background: "var(--primary-color)",
                          },
                          fontSize: "12px",
                          color: "white",
                        }}
                        onClick={() =>
                          handleApproveReject("approve", roaster?.id)
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          marginLeft: "10px",
                          background: "var(--text-medium)",
                          "&:hover": { background: "var(--text-medium)" },
                          fontSize: "12px",
                          color: "white",
                        }}
                        onClick={() =>
                          handleApproveReject("reject", roaster?.id)
                        }
                      >
                        Reject
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PendingRoaster;
