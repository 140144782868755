import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Box, Button, Typography } from "@mui/material";
import moment from "moment";

const UpdtaeOfficialInfo = ({ formData, handleFormChange }) => {
  const [deptData, setDeptData] = useState([]);

  const [roasterData, setRoasterData] = useState([]);
  const [officeData, setOfficeData] = useState([]);
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/departments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse response body as JSON
      })
      .then((data) => {
        console.log(data);
        setDeptData(data?.data); // Assuming data is already in the correct format
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        // Handle error gracefully
      });
  }, []);
  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/roasters`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setRoasterData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  console.log(roasterData);

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/branches`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setOfficeData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Official Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Select Office</span>
        </label>
        <select
          type="text"
          name="branchId"
          id="branchId"
          value={formData.branchId}
          onChange={handleFormChange}
          placeholder="Office Id"
          required
        >
          <option value="" disabled selected>
            Select Office Here
          </option>
          {officeData.map((data, index) => (
            <option key={index} value={data.id}>
              ({data.branchName})
            </option>
          ))}
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Select Department </span>
        </label>
        <select
          type="text"
          name="deptId"
          id="deptId"
          value={formData?.deptId}
          onChange={handleFormChange}
          placeholder="deptId"
          required
        >
          <option value="" disabled selected>
            Select Department Here
          </option>
          {deptData.map((data, index) => (
            <option key={index} value={data.id}>
              {data.deptName}
            </option>
          ))}
        </select>
      </Box>

      <Typography sx={{ mt: 6, mb: 2, fontWeight: 600 }}>Official</Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Designation</span>
        </label>
        <input
          name="designation"
          id="designation"
          value={formData?.designation}
          onChange={handleFormChange}
          className="input-field"
          type="text"
          placeholder="Enter Designation "
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Role</span>
        </label>
        <input
          name="role"
          id="role"
          value={formData?.role}
          onChange={handleFormChange}
          className="input-field"
          type="text"
          placeholder="Enter Role "
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Joining Date</span>
        </label>
        <input
          name="joiningDate"
          id="joiningDate"
          value={formData?.joiningDate}
          onChange={handleFormChange}
          className="input-field"
          type="date"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Employee Type</span>
        </label>
        {/* <input
      name="employeeType"
      id="employeeType"
      value={formData?.employeeType}
      onChange={handleFormChange}
      className="input-field"
      type="text"
      placeholder="Enter Employee Type "
    /> */}
        <select
          type="text"
          name="employeeType"
          id="employeeType"
          value={formData?.employeeType}
          onChange={handleFormChange}
          placeholder="Enter Employee Type "
          required
        >
          <option value="" disabled selected>
            Enter Employee Type
          </option>

          <option value="permanent">Permanent</option>
          <option value="trainee">Trainee</option>
          <option value="freelancer">Freelancer</option>
          <option value="seasonal">Seasonal</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Job Type</span>
        </label>
        {/* <input
      name="jobType"
      id="jobType"
      value={formData?.jobType}
      onChange={handleFormChange}
      className="input-field"
      type="text"
      placeholder="Enter Job Type "
    /> */}
        <select
          type="text"
          name="jobType"
          id="jobType"
          value={formData?.jobType}
          onChange={handleFormChange}
          placeholder="Enter Job Type "
          required
        >
          <option value="" disabled selected>
            Enter Job Type
          </option>

          <option value="full-time">Full-Time</option>
          <option value="part-time">Part-Time</option>
          <option value="hybrid">Hybrid</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Job Location</span>
        </label>
        {/* <input
      name="jobLocation"
      id="jobLocation"
      value={formData?.jobLocation}
      onChange={handleFormChange}
      className="input-field"
      type="text"
      placeholder="Enter Job Location "
    /> */}
        <select
          type="text"
          name="jobLocation"
          id="jobLocation"
          value={formData?.jobLocation}
          onChange={handleFormChange}
          placeholder="Enter Job Type "
          required
        >
          <option value="" disabled selected>
            Enter Job Location
          </option>

          <option value="remote">Remote</option>
          <option value="on-site">On Site</option>
        </select>
      </Box>

      <Typography sx={{ mt: 6, mb: 2, fontWeight: 600 }}>
        Contact Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Phone Number</span>
        </label>
        <input
          name="officialPhone"
          id="officialPhone"
          value={formData?.officialPhone}
          onChange={handleFormChange}
          className="input-field"
          type="number"
          placeholder="Enter Phone Number "
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Email</span>
        </label>
        <input
          name="email"
          id="email"
          value={formData?.email}
          onChange={handleFormChange}
          className="input-field"
          type="text"
          placeholder="Enter Email "
        />
      </Box>
    </Box>
  );
};

export default UpdtaeOfficialInfo;
