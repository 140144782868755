import { Box, Typography } from "@mui/material";
import React from "react";

const UpdateEquipment = ({ formData, handleFormChange }) => {
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Equipment Allocation
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Allocate Laptop</span>
        </label>
        <select
          type="text"
          name="laptop"
          id="laptop"
          value={formData?.laptop}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Mobile</span>
        </label>
        <select
          type="text"
          name="mobile"
          id="mobile"
          value={formData?.mobile}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Mouse</span>
        </label>
        <select
          type="text"
          name="mouse"
          id="mouse"
          value={formData?.mouse}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Keyboard</span>
        </label>
        <select
          type="text"
          name="keyboard"
          id="keyboard"
          value={formData?.keyboard}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>

      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Headphone </span>
        </label>
        <select
          type="text"
          name="headphone"
          id="headphone"
          value={formData?.headphone}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Allocate Other Things </span>
        </label>
        <select
          type="text"
          name="otherAllocation"
          id="otherAllocation"
          value={formData?.otherAllocation}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
    </Box>
  );
};

export default UpdateEquipment;
