import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { styled } from "@mui/system";

const categoryOptions = [
  {
    name: "House Rules",
    value: "house-rules",
  },
  {
    name: "Birthday",
    value: "birthday-on-board",
  },
  {
    name: "Promotions",
    value: "promotions",
  },
  {
    name: "Holidays",
    value: "holidays",
  },
  {
    name: "Attendance Rules",
    value: "attendance-rules",
  },
  {
    name: "Celebrations",
    value: "celebrations",
  },
  {
    name: "Festival",
    value: "festival",
  },
  {
    name: "Cultural Program",
    value: "cultural-program",
  },
  {
    name: "House Party",
    value: "house-party",
  },
  {
    name: "Others",
    value: "others",
  },
];

const CustomSelect = styled(Select)({
  width: "100%", // Initial fixed width
  minWidth: "200px", // Minimum width to prevent shrinking
  "&.MuiOutlinedInput-root": {
    border: "none", // Remove border from root element
  },
  "& .MuiSelect-select": {
    display: "flex",
    flexGrow: 1,
  },
});

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "black",
  "&.Mui-selected": {
    backgroundColor: "var(--primary-color)", // Set background color for selected items
    color: "white",
    "&:hover": {
      color: "white", // Change text color on hover
      backgroundColor: "var(--primary-color)",
    },
  },
}));

const AddNotice = () => {
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({
    subject: "",
    description: "",
  });
  const user = secureLocalStorage.getItem("admin-info");
  const [officeData, setOfficeData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const token = user?.accessToken;

  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [selectedDept, setSelectedDept] = React.useState([]);
  const [selectedOffice, setSelectedOffice] = React.useState([]);

  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  const handleOfficeChange = (event) => {
    setSelectedOffice(event.target.value);
  };

  const handleDeptChange = (event) => {
    setSelectedDept(event.target.value);
  };

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/departments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDeptData(data?.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/branches`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setOfficeData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const url = "http://192.168.1.103:5000/api/company/notices";
    const url = "https://flyfarerp-413411.de.r.appspot.com/api/company/notices";

    const body = {
      branchIds: officeData
        .map((data) => {
          if (selectedOffice.includes(data?.branchName)) {
            return data.id;
          }
        })
        .filter((data) => data),
      deptIds: deptData
        .map((data) => {
          if (selectedDept.includes(data?.deptName)) {
            return data.id;
          }
        })
        .filter((data) => data),
      noticeType: selectedCategories,
      subject: formInfo?.subject,
      description: formInfo?.description,
    };

    // console.log(body);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      // console.log(data);

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/notice");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: data?.error?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try again",
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      });
    }
  };

  // console.log(selectedCategories, selectedDept, selectedOffice);

  // console.log(selectedOffice);

  // console.log(deptData);

  return (
    <Box
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
          borderBottom: "1px solid var(--border-color)",
          borderRadius: "0",
        },
      }}
    >
      <Box ml={1}>
        <Typography variant="title" className="page-title">
          Add Notice
        </Typography>
      </Box>

      <Box sx={{ p: 1, mt: 2 }}>
        <Box>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel> Select Categories </InputLabel>
            <CustomSelect
              value={selectedCategories}
              onChange={handleCategoryChange}
              input={<OutlinedInput label="Multiple Select" />}
            >
              {categoryOptions.map((option, i) => (
                <CustomMenuItem key={i} value={option.value}>
                  {option.name}
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </Box>

        <Box mt={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel> Select Office </InputLabel>
            <CustomSelect
              multiple
              value={selectedOffice}
              onChange={handleOfficeChange}
              input={<OutlinedInput label="Multiple Select" />}
            >
              {officeData.map((data, index) => (
                <CustomMenuItem key={index} value={data.branchName}>
                  ({data.branchName})
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </Box>

        <Box mt={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel> Select Department </InputLabel>
            <CustomSelect
              multiple
              value={selectedDept}
              onChange={handleDeptChange}
              input={<OutlinedInput label="Multiple Select" />}
            >
              {deptData.map((data, index) => (
                <CustomMenuItem key={index} value={data.deptName}>
                  ({data.deptName})
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </Box>

        <Box className="input-container">
          <input
            name="subject"
            id="subject"
            value={formInfo?.subject}
            onChange={handleChange}
            className="input-field"
            type="text"
            placeholder="Notice Title"
            required
          />
        </Box>

        <Box className="input-container">
          <input
            name="description"
            id="description"
            value={formInfo?.description}
            onChange={handleChange}
            className="input-field"
            type="text"
            placeholder="Notice Description"
            required
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            sx={{
              color: "#fff",
              background: "var(--primary-color)",
              mt: 2,
              "&:hover": {
                background: "var(--primary-color)",
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddNotice;
