import { Box, Typography } from "@mui/material";
import React from "react";

const UpdateContactInfo = ({ formData, handleFormChange }) => {
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Personal Contact Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Phone Number</span>
        </label>
        <input
          value={formData.personalPhone}
          onChange={handleFormChange}
          name="personalPhone"
          id="personalPhone"
          className="input-field"
          type="number"
          placeholder="Enter Your Number"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Email</span>
        </label>
        <input
          value={formData.personalEmail}
          onChange={handleFormChange}
          name="personalEmail"
          id="personalEmail"
          className="input-field"
          type="text"
          placeholder="Enter Your Email"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Present Address</span>
        </label>
        <input
          value={formData.presentAddress}
          onChange={handleFormChange}
          name="presentAddress"
          id="presentAddress"
          className="input-field"
          type="text"
          placeholder="Enter Your Present Address"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Parmanent Address</span>
        </label>
        <input
          value={formData.permanentAddress}
          onChange={handleFormChange}
          name="permanentAddress"
          id="permanentAddress"
          className="input-field"
          type="text"
          placeholder="Enter Your Permanent Address"
        />
      </Box>

      <Typography sx={{ mt: 4, fontWeight: 600, mb: 1 }}>
        Emergency Contact Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Phone Number</span>
        </label>
        <input
          value={formData.emergencyContact}
          onChange={handleFormChange}
          name="emergencyContact"
          id="emergencyContact"
          className="input-field"
          type="number"
          placeholder="Enter Your Emergency Number"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Relation</span>
        </label>
        <input
          value={formData.emergencyContactRelation}
          onChange={handleFormChange}
          name="emergencyContactRelation"
          id="emergencyContactRelation"
          className="input-field"
          type="text"
          placeholder="Enter Your Relation "
        />
      </Box>
    </Box>
  );
};

export default UpdateContactInfo;
