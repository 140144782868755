import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { ReactComponent as LeftArrowIcon } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svg/rightArrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Swal from "sweetalert2";

const headers = [
  "Employee Name",
  "Designation",
  "Salary Status",
  "Employee Approval",
  "Basic Salary",
  "Home Allowance",
  "Medical Allowance",
  "Transportation Allowance",
  "Gross Salary",
];

const getCurrentMonth = (date) => {
  return date.toLocaleString("default", { month: "long" });
};

const EmployeeSalary = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const navigate = useNavigate();
  const location = useLocation();
  const branchId = location?.state?.branchId;
  const deptId = location?.state?.deptId;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [salarySummary, setSalarySummary] = useState([]);
  const [deptSalarySummary, setDeptSalarySummary] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  console.log("selectedRows", selectedRows);

  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);

    const currentMonth = new Date().getMonth(); // Get current month

    if (
      newDate.getMonth() <= currentMonth ||
      newDate.getFullYear() < new Date().getFullYear()
    ) {
      setCurrentDate(newDate);
      setCurrentMonth(newDate.getMonth());
    } else {
      console.log("Cannot go beyond the current month.");
    }
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
    setCurrentMonth(newDate.getMonth());
  };

  const handleMasterCheckboxChange = (event) => {
    let isChecked = event.target.checked;

    if (allCheck === false) {
      isChecked = true;
      setAllCheck(true);
    } else {
      setAllCheck(false);
      isChecked = false;
    }

    if (isChecked) {
      const allselectedEmployee = salarySummary
        ?.filter(
          (data) =>
            data.salaryStatus !== "published" && data.salaryStatus !== "pending"
        )
        .map((data) => ({
          id: data.id,
          firstname: data.firstname,
          lastname: data.lastname,
          deptName: data.deptName,
          salaryId: data?.salaryId,
        }));
      setSelectedRows(allselectedEmployee);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSingleCheckboxChange = (
    event,
    id,
    firstname,
    lastname,
    deptName
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows((prevselectedEmployee) => [
        ...prevselectedEmployee,
        { id, firstname, lastname, deptName },
      ]);
    } else {
      setSelectedRows((prevselectedEmployee) =>
        prevselectedEmployee.filter((row) => row.id !== id)
      );
    }
  };

  useEffect(() => {
    const fetchSalary = async () => {
      fetch(
        `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/employees?month=${
          currentDate.getMonth() + 1
        }&year=${currentDate.getFullYear()}&branchId=${branchId}&deptId=${deptId}`,
        // `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/employees?month=${currentDate.getMonth()}&year=${currentDate.getFullYear()}&branchId=${branchId}&deptId=${deptId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          setSalarySummary(data?.data);
        })
        .catch((err) => console.error(err.message));

      fetch(
        `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/employees/summary?month=${
          currentDate.getMonth() + 1
        }&year=${currentDate.getFullYear()}&branchId=${branchId}&deptId=${deptId}`,
        // `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/employees/summary?month=${currentDate.getMonth()}&year=${currentDate.getFullYear()}&branchId=${branchId}&deptId=${deptId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          setDeptSalarySummary(data?.data);
        })
        .catch((err) => console.error(err.message));
    };

    fetchSalary();
  }, []);

  const publishSalary = () => {
    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/publish`;
    const salaryIds = selectedRows
      .filter((data) => {
        return data?.salaryId !== null;
      })
      .map((data) => data.salaryId);

    const body = JSON.stringify({
      salaryIds: salaryIds,
    });

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.success === true) {
          Swal.fire({
            icon: "success",
            text: data?.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => console.error(err.message));
  };

  return (
    <Box
      sx={{
        // position: "relative",
        height: "100%",
        "& .MuiAccordionSummary-root": {
          minHeight: "35px",
          margin: "0",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0",
        },
        "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
          {
            minHeight: "35px",
            margin: "0",
          },
        "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded ": {
          margin: "0",
        },
      }}
    >
      <Box
        sx={{ display: "flex", gap: "25px", justifyContent: "space-between" }}
      >
        <Typography variant="title" className="page-title">
          {salarySummary[0]?.deptName} Department All Employee,{" "}
          {getCurrentMonth(currentDate)}, {currentDate.getFullYear()}
        </Typography>

        <Box className="date-change-container" sx={{ flex: "none" }}>
          <button
            className="previous-next-button"
            onClick={handlePreviousMonth}
            sx={{ width: "34px", height: "22px" }}
          >
            <LeftArrowIcon />
          </button>
          <button
            className="previous-next-button"
            onClick={handleNextMonth}
            sx={{ width: "34px", height: "22px" }}
          >
            <RightArrowIcon />
          </button>
        </Box>
      </Box>

      <Accordion
        v
        sx={{ bgcolor: "var(--light-pr-clr)", borderRadius: "4px", mt: 2 }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={{ fontSize: "14px", color: "var(--primary-color)" }}>
            {salarySummary[0]?.deptName} Department Salary Overview
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              {deptSalarySummary?.map((summary, i) => (
                <Box
                  key={i}
                  sx={{
                    bgcolor: "#fff",
                    p: 1,
                    borderRadius: "4px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    {summary?.title}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "var(--primary-color)",
                      }}
                    >
                      {summary?.employees}
                    </span>
                  </Typography>

                  <Typography sx={{ fontSize: "12px" }}>
                    {/* {i === 0 ? "" : "Total"} {summary?.title} */}
                    Total
                  </Typography>
                  <Typography
                    sx={{ fontSize: "18px", color: "var(--primary-color)" }}
                  >
                    BDT : {summary?.totalGross}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={allCheck}
                    onChange={handleMasterCheckboxChange}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "Var(--primary-color)", // Change to the desired color
                      },
                    }}
                  />
                </TableCell>

                {headers.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {salarySummary.map((salary, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Checkbox
                      disabled={
                        salary?.salaryStatus === "pending" ||
                        salary?.salaryStatus === "published"
                          ? true
                          : false
                      }
                      checked={
                        salary?.salaryStatus !== "pending" &&
                        salary?.salaryStatus !== "published" &&
                        selectedRows.some((row) => row?.id === salary?.id)
                      }
                      onChange={(event) =>
                        handleSingleCheckboxChange(
                          event,
                          salary?.id,
                          salary?.firstname,
                          salary?.lastname,
                          salary?.deptName
                        )
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color:
                            salary?.salaryStatus === "pending" ||
                            salary?.salaryStatus === "published"
                              ? ""
                              : "Var(--primary-color)", // Change to the desired color
                        },
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <Button
                      sx={{
                        backgroundColor: "var(--primary-color)",
                        color: "#ffffff",
                        ":hover": { bgcolor: "var(--primary-color)" },
                        fontSize: "12px",
                        textTransform: "capitalize",
                        minWidth: "160px",
                      }}
                      onClick={() => {
                        navigate("/dashboard/salaryBuild", {
                          state: {
                            currentDate,
                            locationData: {
                              employeeId: salary?.id,
                            },
                          },
                        });
                      }}
                    >
                      {salary?.firstname} {salary?.lastname}
                    </Button>
                  </TableCell>
                  <TableCell>{salary?.designation}</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {salary?.salaryStatus}
                  </TableCell>
                  <TableCell>{salary?.employeeApproval}</TableCell>
                  <TableCell>{salary?.basicSalary}</TableCell>
                  <TableCell>{salary?.homeAllowance}</TableCell>
                  <TableCell>{salary?.medicalAllowance}</TableCell>
                  <TableCell>{salary?.transportationAllowance}</TableCell>
                  <TableCell>{salary?.grossSalary}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Button
        sx={{
          backgroundColor: "var(--primary-color)",
          color: "#ffffff",
          ":hover": {
            bgcolor: "var(--primary-color)",
          },
          width: "100%",
          // position: "fixed",
          mt: "20px",
        }}
        onClick={() => publishSalary()}
      >
        Publish {getCurrentMonth(currentDate)} {currentDate.getFullYear()}{" "}
        {salarySummary[0]?.deptName} Department Salary
      </Button>
    </Box>
  );
};

export default EmployeeSalary;
