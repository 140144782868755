import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import AddEmployeeTabs from "./AddEmployeeTabs";

const AddEmployee = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    bloodGroup: "",
    image: "",
    nid: "",
    religion: "",
    nationality: "",
    presentAddress: "",
    permanentAddress: "",
    personalPhone: "",
    personalEmail: "",
    email: "",
    officialPhone: "",
    emergencyContact: "",
    emergencyContactRelation: "",
    designation: "",
    role: "",
    joiningDate: "",
    employeeType: "",
    jobType: "",
    jobLocation: "",
    basicSalary: "",
    homeAllowance: "",
    medicalAllowance: "",
    transportationAllowance: "",
    casualLeave: "",
    sickLeave: "",
    advancedSalaryEligibility: "",
    loanSalaryEligibility: "",
    casualLeaveEligibility: "",
    havingLunch: "",
    overtimeEligibility: "",
    shift1: "",
    shift2: "",
    shift3: "",
    shift4: "",
    deptId: "",
    branchId: "",
    breakTime: "",
    laptop: "",
    mobile: "",
    mouse: "",
    keyboard: "",
    headphone: "",
    otherAllocation: "",
  });

 

  return (
    <Box>
      <Box ml={1}>
        <Typography variant="title" className="page-title">
          Add Employee
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <AddEmployeeTabs formData={formData} setFormData={setFormData} />
      </Box>

      {/* <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Basic Salary</span>
        </label>
        <input id="salary" className="input-field" type="text" />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Home Allowance</span>
        </label>
        <input id="homeAllowance" className="input-field" type="text" />
      </Box> */}
    </Box>
  );
};

export default AddEmployee;
