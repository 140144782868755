import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const AddDepartment = () => {
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const user = secureLocalStorage.getItem("admin-info");

  const token = user?.accessToken;

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url =
      "https://flyfarerp-413411.de.r.appspot.com/api/company/departments";
    const body = JSON.stringify({
      deptName: formInfo.name,
      deptEmail: formInfo.email,
      deptPhone: formInfo.phone,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/department");
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      }).then(function () {
        navigate("/dashboard/department/addDepartment");
      });
    }
  };
  return (
    <Box>
      <Box ml={1}>
        <Typography variant="title" className="page-title">
          Add Department
        </Typography>
      </Box>

      <Box sx={{ p: 1, mt: 2 }}>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Name</span>
          </label>
          <input
            value={formInfo?.name}
            onChange={handleChange}
            name="name"
            id="name"
            className="input-field"
            type="text"
            placeholder="Enter Department Name"
            required
          />
        </Box>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Email</span>
          </label>
          <input
            value={formInfo?.email}
            onChange={handleChange}
            name="email"
            id="email"
            className="input-field"
            type="text"
            placeholder="Enter Email"
            required
          />
        </Box>
        <Box className="input-container">
          <label htmlFor="salary" className="input-label">
            <span>Phone Number</span>
          </label>
          <input
            name="phone"
            id="phone"
            value={formInfo?.phone}
            onChange={handleChange}
            className="input-field"
            type="text"
            placeholder="Enter Phone Number"
            required
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            sx={{
              color: "#fff",
              background: "var(--primary-color)",
              mt: 2,
              "&:hover": {
                background: "var(--primary-color)",
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddDepartment;
