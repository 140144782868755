import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { ReactComponent as LeftArrowIcon } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svg/rightArrow.svg";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const headers = [
  "Office Name",
  "Total Departments",
  "Total Employees",
  "Paid Employees",
  "Total Paid",
  "Total Net Salary",
  "Total Gross Salary",
  "Total Draft Salaries",
  "Total Built Salaries",
  "Total Published",
  "Total Accepted",
  "Total Pending",
  "Total Rejected",
  "Action",
];

const getCurrentMonth = (date) => {
  return date.toLocaleString("default", { month: "long" });
};

const OfficeSalary = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [salarySummary, setSalarySummary] = useState([]);

  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);

    const currentMonth = new Date().getMonth(); // Get current month

    if (
      newDate.getMonth() <= currentMonth ||
      newDate.getFullYear() < new Date().getFullYear()
    ) {
      setCurrentDate(newDate);
      setCurrentMonth(newDate.getMonth());
      // Call function to fetch attendance data for new month
      // fetchAttendanceData(newDate);
    } else {
      console.log("Cannot go beyond the current month.");
    }
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
    setCurrentMonth(newDate.getMonth());
    // Call function to fetch attendance data for new month
    // fetchAttendanceData(newDate);
  };

  useEffect(() => {
    const fetchSalary = async () => {
      fetch(
        `https://flyfarerp-413411.de.r.appspot.com/api/company/salaries/sallary-summary/?month=${currentDate.getMonth()}&year=${currentDate.getFullYear()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSalarySummary(data?.data);
        })
        .catch((err) => console.error(err.message));
    };

    fetchSalary();
  }, []);

  return (
    <Box>
      <Box
        sx={{ display: "flex", gap: "25px", justifyContent: "space-between" }}
      >
        <Typography variant="title" className="page-title">
          All Office Salary {getCurrentMonth(currentDate)},{" "}
          {currentDate.getFullYear()}
        </Typography>

        <Box className="date-change-container">
          <Box className="date-change-container" sx={{ flex: "none" }}>
            <button
              className="previous-next-button"
              onClick={handlePreviousMonth}
              sx={{ width: "34px", height: "22px" }}
            >
              <LeftArrowIcon />
            </button>
            <button
              className="previous-next-button"
              onClick={handleNextMonth}
              sx={{ width: "34px", height: "22px" }}
            >
              <RightArrowIcon />
            </button>
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1800 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {salarySummary.map((salary, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Button
                      sx={{
                        backgroundColor: "var(--primary-color)",
                        color: "#ffffff",
                        ":hover": { bgcolor: "var(--primary-color)" },
                        fontSize: "12px",
                        textTransform: "capitalize",
                        minWidth: "140px",
                      }}
                      onClick={() =>
                        navigate("/dashboard/deptSalary", {
                          state: {
                            branchId: salary?.branchId,
                          },
                        })
                      }
                    >
                      {salary?.branchName}
                    </Button>
                  </TableCell>
                  <TableCell>{salary?.departments}</TableCell>
                  <TableCell>{salary?.totalEmployees}</TableCell>
                  <TableCell>{salary?.paidEmployees}</TableCell>
                  <TableCell>{salary?.totalPaid}</TableCell>
                  <TableCell>{salary?.totalNetSalary}</TableCell>
                  <TableCell>{salary?.totalGrossSalary}</TableCell>
                  <TableCell>{salary?.totalDraftSalaries}</TableCell>
                  <TableCell>{salary?.totalBuiltSalaries}</TableCell>
                  <TableCell>{salary?.totalPublished}</TableCell>
                  <TableCell>{salary?.totalAccepted}</TableCell>
                  <TableCell>{salary?.totalPending}</TableCell>
                  <TableCell>{salary?.totalRejected}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default OfficeSalary;
