import { Box, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        bgcolor: "var(--primary-color)",
        minHeight: "100%",
        li: { color: "var(--text-medium)", py: 0.5 },
      }}
    >
      <Navbar />

      <Box
        sx={{
          display: "flex",
          mt: 2.5,
          maxWidth: "1400px",
          margin: "0 auto",
          pb: 5,
        }}
      >
        <Typography sx={{ fontSize: "47px", color: "white" }}>
          Privacy Policy
        </Typography>
      </Box>

      <Box sx={{ bgcolor: "white", borderRadius: "150px 150px 0 0", py: 10 }}>
        <Box sx={{ maxWidth: "1400px", margin: "0 auto" }}>
          <Typography>Last updated: 09 June 2024</Typography>
          <Typography my={2}>
            At HRM Pro, along with our associated applications and subsidiaries,
            we prioritize respecting your data protection rights and ensuring
            your privacy is fully safeguarded. Our privacy policy outlines in
            detail how we collect, utilize, disclose, retain, and protect your
            information. Please review this information carefully to understand
            how we secure your data.
          </Typography>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1 }}>
              1. Information We Collect
            </Typography>
            <Typography pl={2}>
              When you complete a form or provide your personal details on our
              website, we collect this information for future use.
            </Typography>
            <Typography pl={2} pt={1}>
              This may include your name, email address, phone number, and
              mailing address when you submit a request on our site.
              Additionally, we gather data such as device location, IP address,
              browsing history, browsing details, transactions, and other
              relevant information needed to offer you optimal services.
            </Typography>
            {/* <Typography pl={2} pt={1}>
              <strong>Employment Information:</strong> Information related to
              your employment, such as job title, department, manager's name,
              salary, work schedule, attendance, performance evaluations, and
              other employment-related information.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Technical Data:</strong> Information about your device and
              internet connection, such as IP address, browser type, operating
              system, and access times.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Usage Data:</strong> Information about how you use the
              EMS, such as the features you use, the pages you visit, and other
              actions on the platform.
            </Typography> */}
          </Box>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              2. Contact Information
            </Typography>
            <Typography pl={2}>
              If you have any questions, comments, requests, or concerns
              regarding this privacy policy or our privacy practices, or if you
              wish to opt out of future communications or exercise other
              privacy-related rights, please contact us at:
            </Typography>

            <ul>
              <li>Email: info@flyfartech</li>
              <li>Phone: +880 138414454145</li>
              <li>
                Hours: Saturday to Thursday, 09:00 AM to 06:00 PM (GMT +6)
              </li>
              <li>
                Address: Ka 11/2A, Bashundhora R/A Road, Jagannathpur, Dhaka
                1229
              </li>
            </ul>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              3. How We Use Your Information
            </Typography>
            <Typography pl={2}>
              We utilize the collected information in various ways, including
              to:
            </Typography>

            <ul>
              <li>Operate and maintain our website</li>
              <li>Enhance, personalize, and expand our website</li>
              <li>Understand and analyze your usage of our website</li>
              <li>
                Develop new products, services, features, and functionalities
              </li>
              <li>
                Communicate with you directly or through partners for customer
                service, updates, and other information related to the website,
                as well as for marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Detect and prevent fraud</li>
            </ul>
          </Box>

          {/* <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              3. How We Share Your Information
            </Typography>
            <Typography pl={2}>We may share your information with:</Typography>
            <Typography pl={2} pt={1}>
              <strong>Authorized Personnel:</strong> Your information may be
              accessed by authorized personnel within our company who need it to
              perform their job functions.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Service Providers:</strong> We may share your information
              with third-party service providers who perform services on our
              behalf, such as payroll processing, data hosting, and technical
              support.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Legal Obligations:</strong> We may disclose your
              information if required to do so by law or in response to valid
              requests by public authorities.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Business Transfers:</strong> In the event of a merger,
              acquisition, or sale of all or a portion of our assets, your
              information may be transferred to the new owner.
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              4. Data Security
            </Typography>
            <Typography pl={2}>
              We take appropriate security measures to protect your information
              from unauthorized access, disclosure, alteration, and destruction.
              These measures include encryption, access controls, and regular
              security assessments.
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              5. Data Retention
            </Typography>
            <Typography pl={2}>
              We retain your personal information for as long as necessary to
              fulfill the purposes for which it was collected, or as required by
              law. When your information is no longer needed, we will securely
              delete or anonymize it.
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              6. Your Rights
            </Typography>
            <Typography pl={2}>
              You have the following rights regarding your personal information:
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Access:</strong> You have the right to request access to
              the personal information we hold about you.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Correction:</strong> You have the right to request the
              correction of inaccurate or incomplete personal information.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Deletion:</strong> You have the right to request the
              deletion of your personal information, subject to certain
              exceptions.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Restriction:</strong> You have the right to request the
              restriction of processing of your personal information.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Portability:</strong> You have the right to request a copy
              of your personal information in a structured, commonly used, and
              machine-readable format.
            </Typography>
            <Typography pl={2} pt={1}>
              <strong>Objection:</strong> You have the right to object to the
              processing of your personal information based on legitimate
              interests.
            </Typography>
            <Typography pl={2} pt={1}>
              To exercise these rights, please contact us at hrmpro@gmail.com
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              7. Changes to This Privacy Policy
            </Typography>
            <Typography pl={2}>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              EMS and updating the "Last updated" date at the top of this page.
              You are advised to review this Privacy Policy periodically for any
              changes.
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "20px", mb: 1, mt: 2 }}>
              8. Contact Us
            </Typography>
            <Typography pl={2}>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at hrmpro@gmail.com
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
