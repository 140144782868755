import { Box, Button, Typography } from "@mui/material";
import React from "react";

const SalaryInfo = ({ formData, handleFormChange }) => {
  return (
    <Box>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Salary Information
      </Typography>
      <Box className="input-container">
        <label htmlFor="salary" className="input-label">
          <span>Basic Salary</span>
        </label>
        <input
          type="number"
          name="basicSalary"
          id="basicSalary"
          value={formData?.basicSalary}
          onChange={handleFormChange}
          className="input-field"
          placeholder="Enter Basic Salary"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Home Allowance</span>
        </label>
        <input
          type="number"
          name="homeAllowance"
          id="homeAllowance"
          value={formData?.homeAllowance}
          onChange={handleFormChange}
          className="input-field"
          placeholder="Enter Home Allowance"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Medical Allowance</span>
        </label>
        <input
          type="number"
          name="medicalAllowance"
          id="medicalAllowance"
          value={formData?.medicalAllowance}
          onChange={handleFormChange}
          className="input-field"
          placeholder="Enter Medical Allowance"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Transportation Allowance</span>
        </label>
        <input
          type="number"
          name="transportationAllowance"
          id="transportationAllowance"
          value={formData?.transportationAllowance}
          onChange={handleFormChange}
          className="input-field"
          placeholder="Enter Transportation Allowance"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Casual Leave</span>
        </label>
        <input
          type="number"
          name="casualLeave"
          id="casualLeave"
          value={formData?.casualLeave}
          onChange={handleFormChange}
          className="input-field"
          placeholder="Enter Casual Leave"
        />
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Sick Leave</span>
        </label>
        <input
          type="number"
          name="sickLeave"
          id="sickLeave"
          value={formData?.sickLeave}
          onChange={handleFormChange}
          className="input-field"
          placeholder="Enter Sick Leave"
        />
      </Box>

      <Box className="input-container" mt={4}>
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Advance Salary </span>
        </label>
        <select
          type="text"
          name="advancedSalaryEligibility"
          id="advancedSalaryEligibility"
          value={formData?.advancedSalaryEligibility}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Loan </span>
        </label>
        <select
          type="text"
          name="loanSalaryEligibility"
          id="loanSalaryEligibility"
          value={formData?.loanSalaryEligibility}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Casual Leave </span>
        </label>
        <select
          type="text"
          name="casualLeaveEligibility"
          id="casualLeaveEligibility"
          value={formData?.casualLeaveEligibility}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Having Lunch</span>
        </label>
        <select
          type="text"
          name="havingLunch"
          id="havingLunch"
          value={formData?.havingLunch}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <span>Eligible for Overtime </span>
        </label>
        <select
          type="text"
          name="overtimeEligibility"
          id="overtimeEligibility"
          value={formData?.overtimeEligibility}
          onChange={handleFormChange}
        >
          <option value="" disabled selected>
            Select an option
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </Box>
    </Box>
  );
};

export default SalaryInfo;
