import { Box, Button, Chip, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import { styled } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import APILoader from "../../components/APILoader/APILoader";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "black",
  "&.Mui-selected": {
    backgroundColor: "var(--primary-color)", // Set background color for selected items
    color: "white",
    "&:hover": {
      color: "white", // Change text color on hover
      backgroundColor: "var(--primary-color)",
    },
  },
}));

const weekends = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const selectStyle = {
  fontSize: "14px",
  "& .MuiSelect-select": { py: "8px" },
  "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0px" },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
    borderColor: "transparent",
  },
  "& .MuiMenu-paper": { boxShadow: "10px 10px 10px black" },
};

const chip = {
  text: { display: "flex", alignItems: "center", gap: 1 },
  btn: { fontSize: "14px", cursor: "pointer" },
};

const AddRoster = () => {
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({});
  const [selectedWeekends, setSelectedWeekends] = useState([]);
  const [selectedWorkDays, setSelectedWorkDays] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [office, setOffice] = useState([]);
  const [department, setDepartment] = useState([]);
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  // console.log("selectedBranch", selectedBranch[0]?.id);
  // console.log("selectedDept", selectedDept[0]?.id);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/branches`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to fetch departments");
          }
          return res.json();
        })
        .then((data) => {
          setOffice(data?.data);
        })
        .catch((error) => {
          console.error("Error fetching departments:", error);
        });

      fetch(
        `https://flyfarerp-413411.de.r.appspot.com/api/company/departments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to fetch departments");
          }
          return res.json();
        })
        .then((data) => {
          setDepartment(data?.data);
        })
        .catch((error) => {
          console.error("Error fetching departments:", error);
        });

      setIsLoading(false);
    };

    fetchData();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "checkIn" || name === "checkOut") {
      // Format time for checkIn and checkOut fields
      let formatTime = moment(value, "HH:mm").format("HH:mm:ss");

      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

      // Check if the input value matches the time format
      if (!timeRegex.test(formatTime)) {
        // Invalid time format, do not update state
        return;
      }

      // Update formInfo with the formatted time value
      setFormInfo((prevState) => ({
        ...prevState,
        [name]: formatTime,
      }));
    } else {
      // Update roasterName directly with the input value
      setFormInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tempWeekend = selectedWeekends.join(",");
    const url = "https://flyfarerp-413411.de.r.appspot.com/api/v2/company/roasters";
    // const url = "http://192.168.1.103:5000/api/v2/company/roasters";
    let body;

    if (user?.user?.role === "manager") {
      body = JSON.stringify({
        checkIn: formInfo.checkIn,
        checkOut: formInfo.checkOut,
        roasterName: formInfo.roasterName,
        // weekends: tempWeekend,
        bufferTime: Number(formInfo.bufferTime) || 0,
        breakTime: Number(formInfo.breakTime),
      });
    } else {
      body = JSON.stringify({
        checkIn: formInfo.checkIn,
        checkOut: formInfo.checkOut,
        roasterName: formInfo.roasterName,
        // weekends: tempWeekend,
        bufferTime: Number(formInfo.bufferTime) || 0,
        // applicableDays: selectedWorkDays.join(","),
        // jobLocation: selectedJobLocation,
        breakTime: Number(formInfo.breakTime),
        branchId: selectedBranch[0]?.id,
        deptId: selectedDept[0]?.id,
      });
    }
    // console.log(body);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/roster");
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      }).then(function () {
        document.getElementById("your-form-id").reset();
        navigate("/admin/addRoaster");
      });
    }
  };

  // console.log(selectedDept);

  return (
    <Box sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}>
      <Box ml={1}>
        <Typography variant="title" className="page-title">
          Add Roster
        </Typography>
      </Box>

      {isLoading ? (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      ) : (
        <Box sx={{ p: 1, mt: 2 }}>
          <Box className="input-container">
            <label htmlFor="salary" className="input-label">
              <span>Name</span>
            </label>
            <input
              value={formInfo?.roasterName}
              onChange={handleChange}
              name="roasterName"
              id="roasterName"
              className="input-field"
              type="text"
              placeholder="Enter Roster Name"
              required
            />
          </Box>
          <Box className="input-container">
            <label htmlFor="salary" className="input-label">
              <span>Check In </span>
            </label>
            <input
              value={formInfo?.checkIn}
              onChange={handleChange}
              name="checkIn"
              id="checkIn"
              className="input-field"
              type="time"
              placeholder="Enter Check In"
              required
              style={{ width: "10%" }}
            />
          </Box>
          <Box className="input-container">
            <label htmlFor="salary" className="input-label">
              <span>Check Out</span>
            </label>
            <input
              value={formInfo?.checkOut}
              onChange={handleChange}
              name="checkOut"
              id="checkOut"
              className="input-field"
              type="time"
              placeholder="Enter Check In"
              required
              style={{ width: "10%" }}
            />
          </Box>

          <Box className="input-container">
            <label htmlFor="salary" className="input-label">
              <span>Buffer Time</span>
            </label>
            <input
              value={formInfo?.bufferTime}
              onChange={(e) => {
                if (e.target.value < 31 && e.target.value >= 0) {
                  handleChange(e);
                }
              }}
              name="bufferTime"
              id="bufferTime"
              className="input-field"
              placeholder="01-30 minutes"
              type="number"
              required
            />
          </Box>

          {user?.user?.role !== "manager" && (
            <>
              <Box className="input-container">
                <label htmlFor="office" className="input-label">
                  Select Branch Name
                </label>
                <Select
                  multiple
                  displayEmpty
                  value={selectedBranch}
                  onChange={(e) =>
                    setSelectedBranch([
                      e.target.value[e.target.value.length - 1],
                    ])
                  }
                  sx={{ ...selectStyle, minWidth: "30%", width: "auto" }}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return (
                        <span style={{ color: "var(--text-medium)" }}>
                          Select Branch Name Here
                        </span>
                      );
                    }

                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value, i) => (
                          <span key={i} style={{ color: "var(--text-medium)" }}>
                            {value?.branchName}{" "}
                          </span>
                        ))}
                      </Box>
                    );
                  }}
                >
                  {office.map((branch, i) => (
                    <MenuItem sx={{ fontSize: "14px" }} key={i} value={branch}>
                      {branch.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box className="input-container">
                <label htmlFor="office" className="input-label">
                  Select Department Name
                </label>
                <Select
                  multiple
                  displayEmpty
                  value={selectedDept}
                  onChange={(e) =>
                    setSelectedDept([e.target.value[e.target.value.length - 1]])
                  }
                  sx={{ ...selectStyle, minWidth: "30%", width: "auto" }}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return (
                        <span style={{ color: "var(--text-medium)" }}>
                          Select Department Name Here
                        </span>
                      );
                    }

                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value, i) => (
                          <span key={i} style={{ color: "var(--text-medium)" }}>
                            {value?.deptName}{" "}
                          </span>
                        ))}
                      </Box>
                    );
                  }}
                >
                  {department.map((branch, i) => (
                    <MenuItem sx={{ fontSize: "14px" }} key={i} value={branch}>
                      {branch.deptName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </>
          )}

          <Box className="input-container">
            <label htmlFor="salary" className="input-label">
              <span>Break Time</span>
            </label>
            <input
              value={formInfo?.breakTime}
              onChange={(e) => {
                if (e.target.value < 61 && e.target.value >= 0) {
                  handleChange(e);
                }
              }}
              name="breakTime"
              id="breakTime"
              className="input-field"
              placeholder="01-30 minutes"
              type="number"
              required
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              sx={{
                color: "#fff",
                background: "var(--primary-color)",
                mt: 2,
                "&:hover": {
                  background: "var(--primary-color)",
                },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddRoster;
